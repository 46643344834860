.return-content {
  .order-step {
    max-width: 28.75rem;
    padding-bottom: 24px;
    margin: 0 auto;

    .default-steps-container {
      display: none;
    }

    .desktop-steps-container {
      width: unset;
    }
  }
  .wrapper-checkout-recap-bottom {
    max-width: 28.75rem;
    padding-bottom: 24px;
    margin: 0 auto;
    margin-top: 3rem;
  }
}

.return-confirmation-wrapper {
  height: 100vh;
}

.return-confirmation-content {
  max-width: 28.625rem;
  margin: 0 auto;
}
