.modal-cookies-management {
  .mm-form-check {
    + p {
      margin-left: 1.5rem;
    }
  }
}

#cookies-modal {
  .modal-container {
    height: auto;
  }

  .popup-close {
    top: 1.6rem;
    font-size: 12px;
  }
}
