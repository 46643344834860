.modal {
  .modal-overlay {
    align-items: center;
    justify-content: center;
  }

  .modal-container {
    border-radius: $border-radius-12;
  }
}
.modal-close {
  &.mm-button {
    height: rem(32);
    width: rem(32);
  }
}

.modal-container {
  height: auto;
}
.modal-content {
  padding: 1rem 1rem rem(20);
  p {
    padding: 0 rem(12);
  }
}
