.suggestions-wrapper-container {
  // max-height: var(--search-results-max);
  grid-template-areas:
    'suggestions suggestions suggestions suggestions recommendations recommendations recommendations recommendations recommendations recommendations'
    'suggestions suggestions suggestions suggestions recommendations recommendations recommendations recommendations recommendations recommendations';
  gap: 10px;
  grid-auto-columns: minmax(0, 1fr);
  padding-bottom: 13px;
  padding: 13px;
  overflow: hidden;

  .suggestions-wrapper {
    max-height: unset;

    .mm-menu-search__results {
      max-height: unset;
      height: 100%;
    }
  }

  .search-no-result-decorator-container {
    .swiper-slide {
      max-width: 25%;
      width: 25% !important;
    }
    .swiper-button-prev,
    .swiper-button-next {
      top: 14px;
      display: flex;

      &:after {
        color: black;
        background: #e3e3df80;
        font-size: 18px;
        padding: 5px 15px;
        border-radius: 10px;
      }
    }

    .no-navigation {
      .swiper-button-prev,
      .swiper-button-next {
        display: none !important;
      }
    }

    .swiper-button-prev {
      right: 65px;
      left: unset;
    }
  }

  .container-carousel {
    .tile-image-link {
      aspect-ratio: 2/3;
    }
  }
}

.suggestions-wrapper-container.suggestions-active-false {
  padding-top: 0;
  grid-template-areas:
    'suggestions suggestions suggestions suggestions suggestions suggestions suggestions suggestions suggestions suggestions suggestions suggestions'
    'suggestions suggestions suggestions suggestions suggestions suggestions suggestions suggestions suggestions suggestions suggestions suggestions';

  .left-container {
    padding: 0 !important;
  }

  .left-container .search-recommendations-top {
    margin: 0;
  }

  .page-search-result-container {
    display: none;
  }
}

.mm-form-search-input.mm-form-input:not(.-invalid) input.accessibility-search-input:not(:placeholder-shown):valid {
  background-image: none;
}
