.two-columns-wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  // IBT-3551
  // border-radius: $border-radius-12;
  border-radius: $border-radius-8;

  &.-white-background {
    background: get-color('white');
  }
  .media-position-right & {
    flex-direction: row-reverse;
  }
}

.two-columns__text-region,
.two-columns__media-region {
  width: calc(50%);
}

.two-columns__text-region {
  padding-top: 0;
  padding-bottom: 0;
}

// IBT-3551
.two-columns-component {
  max-width: 1442px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.two-columns-wrapper {
  height: 100%;

  .two-columns__media-region,
  .experience-assets-image_video,
  .image-video-component,
  .gallery__picture {
    height: inherit;
  }
}

.newsletter-journey .MMheadingGroup-inner {
  padding-bottom: 40px;
  padding-top: 40px;
}
