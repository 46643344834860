#country-selector-modal {
  .modal-container {
    width: 1024px;
    height: 75vh;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  form {
    width: 50%;
    margin: 0 auto;
  }

  .subtitle {
    position: absolute;
    top: 26px;
    left: auto;
    width: calc(100% - 100px);
  }

  .section-country-selector {
    padding: 50px;
  }
}

.continent {
  margin: 20px 0;
  .countries {
    columns: 4;
  }
}
