.mm-videoloop {
  //min-height: calc(100vh - $header-height - $menu-v-padding);
  min-height: 0;
  padding-top: map-get($aspect-ratios, '2x3');
  .vjs-tech {
    width: auto;
    transform: translateX(-50%);
    left: 50%;
  }
}
.experience-assets-image_video {
  .image-video-component:not(.ratio) {
    @include ratio();
    &:before {
      padding-top: map-get($aspect-ratios, '9x16');
    }
  }
}

// IBT-3407
.pd-page__content > .experience-assets-image_video {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.plp-top-editorial .experience-assets-image_video .image-video-component:not(.ratio) {
  max-height: 12rem;
}

.experience-assets-image_video .image-video-component.has-video .fit-to-screen-height {
  max-height: 100vh;
  div.vjs-player {
    max-height: 100vh;
  }
}

.experience-component.experience-assets-image_video {
  .image-video-component {
    &.ratio-feature {
      &.ratio-mobile-1x1 {
        @include ratio();
        padding-top: map-get($aspect-ratios, '1x1');
        video {
          aspect-ratio: 1;
        }
      }
      &.ratio-mobile-3x2 {
        @include ratio();
        padding-top: map-get($aspect-ratios, '3x2');

        video {
          aspect-ratio: 3/2;
        }
      }
      &.ratio-mobile-2x3 {
        @include ratio();
        padding-top: map-get($aspect-ratios, '2x3');

        video {
          aspect-ratio: 2/3;
        }
      }
      &.ratio-mobile-4x3 {
        @include ratio();
        padding-top: map-get($aspect-ratios, '4x3');

        video {
          aspect-ratio: 4/3;
        }
      }
      &.ratio-mobile-3x4 {
        @include ratio();
        padding-top: map-get($aspect-ratios, '3x4');

        video {
          aspect-ratio: 3/4;
        }
      }
      &.ratio-mobile-9x16 {
        @include ratio();
        padding-top: map-get($aspect-ratios, '9x16');

        video {
          aspect-ratio: 9/16;
        }
      }
      &.ratio-mobile-16x9 {
        @include ratio();
        padding-top: map-get($aspect-ratios, '16x9');

        video {
          aspect-ratio: 16/9;
        }
      }
    }

    .mm-videoloop {
      padding-top: unset;
    }
  }
}

.mm-menu-mobile__secondary {
  .experience-component.experience-layouts-MMCarousel {
    .image-video-component {
      &.ratio-feature {
          aspect-ratio: 2/3;
      }
  
      picture {
          height: 100%;
      }
    }
  }
}

