#minicart-remove-product-icon {
  color: $black;
  display: none;
  width: 0.75rem;
  height: 0.75rem;
  .mm-icon {
    width: 0.75rem;
    height: 0.75rem;
  }
}

.desktop-minicart-button {
  @include type('global-control-productive-3');
  background: transparent;
  background-color: transparent;
}

#layer-minicart {
  border-radius: 1.125rem;
  display: none;
  max-height: 100vh;
  position: absolute;
  right: 0;
  top: 3.5rem;
  white-space: normal;
  width: 19.375rem;
  z-index: 6;
  &.is-open {
    display: block;
  }
  font-size: rem(14);
  line-height: rem(17);
}

.minicart-modal {
  .go-to-checkout-button {
    width: 100%;
  }
  .modal-header.minicart-header {
    padding: 1.6rem;
    padding-top: 1rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    .modal-close-container {
      height: 16px;
      .modal-close {
        height: 16px;
        width: 16px;
      }
    }
  }
  .modal-content {
    padding: 0.75rem 0;
  }
  .wishlist-action {
    min-height: 2.5rem;
    margin-top: 0.75rem;
    > .col {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
}

.minicart-empty {
  display: flex;
  flex-direction: column;
  .wrapper-login-actions {
    padding-top: 1.6rem;
    .mm-button {
      width: 100%;
    }
  }

  .separator {
    padding-top: 2.6rem;
    padding-bottom: 2.6rem;
    display: flex;
    .line-separator {
      border-bottom: 1px solid lightgrey;
      margin: auto;
    }
    .separator-center {
      text-align: center;
      font-size: 14px;
    }
  }
}

.minicart-item-grid {
  display: flex;
  // display: grid;
  // grid-template-areas:
  //   'product-image product-name'
  //   'product-image price'
  //   'product-image attributes'
  //   'product-image quantity'
  //   'product-image product-actions';
  // grid-template-columns: 5.5rem 1fr;

  .product-image-wrapper {
    // grid-area: product-image;
    img {
      border-radius: 0.5rem;
      height: 7.125rem;
      margin-right: 0.75rem;
      width: 4.75rem;
    }
  }
  .product-attributes {
    @include type('global-control-productive-3');
    line-height: rem(17);

    display: flex;
    .detail-attribute {
      display: flex;
      &.product-color-detail {
        order: 2;
        overflow: hidden;
        .bag-product__details-value {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 9.5rem;
        }
      }
      &.product-size {
        order: 1;
      }
      .size-color-separator {
        margin: 0 0.5rem;
      }
    }
  }

  .product-badge-wrapper {
    min-height: 0.625rem;
    margin-bottom: 1rem;
  }

  .product-name-wrapper {
    // grid-area: product-name;
    h4 {
      @include type('global-control-productive-3');
      margin-bottom: 0.625rem;
      margin-top: 0.25rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 12.25rem;
    }
  }

  .quantity-price-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    button {
      @include type('global-control-productive-3');
      background-color: $ecru-95-10;
      border: none;
      border-radius: 0.5rem;
      opacity: 50%;
      height: 1.5rem;
      width: 2rem;
    }
  }

  .product-actions-wrapper {
    // grid-area: product-actions;
    margin-top: 10px;
    .wish-delete-wrapper {
      border-bottom: none;
      border-top: none;
      .btn-cart-remove {
        justify-content: flex-end;
        padding-bottom: 0;
        padding-top: 0;
        .icon--trash {
          display: none;
        }
      }
    }
  }
}

/** MINCART ITEM  style -->START*/
// .minicart-item-grid {
//   .product-title {
//     text-transform: uppercase;
//   }

//   .product-info-details {
//     flex: 1;
//     width: 100%;

//     .detail-attribute {
//       display: flex;
//       justify-content: flex-start;
//       margin-top: 8px;
//       + .detail-attribute {
//         margin-top: 8px;
//       }
//     }

//     .name-field {
//       margin-right: 4px;
//       text-transform: uppercase;
//     }
//     .value-field {
//       text-align: left;
//       display: flex;
//     }
//   }

//   .cart-edit-link {
//     display: none;
//   }

//   .product-prices-wrapper {
//     text-align: left;
//     justify-self: start;
//     margin-top: 10px;
//     .line-item-price-info {
//       display: none;
//     }
//   }

//   .product-quantity-wrapper {
//     text-transform: uppercase;
//   }
// }

.minicart-message {
  margin-top: 16px;
  text-align: left;
}

.minicart-items-section::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

#minicart-modal,
.minicart-modal {
  .minicart-items-section {
    max-height: 19.25rem;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 0.75rem;
  }

  .product-wrapper {
    border: none;
    margin: 0;
    padding: 0;
    padding-bottom: 1rem;
    position: relative;
    .product-remove-wrapper {
      .js-remove-from-cart {
        background-color: transparent;
        display: none;
        height: 0.75rem;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0.25rem;
        width: 0.75rem;
        &:hover {
          background-color: transparent;
        }
      }
      .confirm-remove-label {
        display: none;
      }
    }
    &:hover {
      .product-details-box {
        width: 12.25rem;
        .product-name-wrapper {
          h4 {
            width: 10.625rem;
          }
        }
      }
      .product-remove-wrapper {
        .js-remove-from-cart {
          display: flex;
          #minicart-remove-product-icon {
            display: block;
          }
        }
      }
    }
    &.odd {
      background-color: $ecru-95-10;
      border-radius: 0.5rem;
      margin-bottom: 0.5rem;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
      padding: 0.5rem;
      width: calc(100% + 1rem);
      .js-remove-from-cart {
        right: 0.5rem;
        top: 0.6rem;
      }
    }
  }
  .modal-bottom-section {
    padding: 0 0.75rem;
  }
  .modal-content {
    backdrop-filter: blur(135px);
    background-color: $ecru-05-95;
    border-radius: 0.75rem;
    box-shadow: 0 0 10px -6px;
    cursor: default;
    overflow-y: unset;
    &.emtpy-minicart-modal-content {
      min-height: rem(333);
      padding: 0.75rem 0.75rem 0.75rem 0.75rem;
      display: flex;
    }
  }
  .empy-minicart-title {
    padding: 0 1.5rem;
    flex: 1;
    h2 {
      @include type('mm-headline-2');
      text-align: center;
      transform: translateY(-50%);
      margin-top: 50%;
    }
  }
}
.line-item-total-price {
  .price {
    display: flex;
    // flex-direction: column;
    .non-adjusted-price {
      margin-right: 0.25rem;
    }
  }
}

.minicart-totals {
  padding: 0.5rem 0;
  .line-totals-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    @include type(global-control-productive-3);
    + .line-totals-item {
      border-top: none;
    }
  }
}

.main-overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #00000087;
  z-index: 5;
  top: unset;
  &.no-top {
    top: 0;
  }
  &.full-height {
    height: 100%;
  }
}
.js-dropdown-content {
  z-index: 6;
}

/** MINICART ITEM  style -->END*/
