.padding-small {
  padding-top: 11rem;
  padding-bottom: 11rem;
}
.padding-medium {
  padding-top: 6rem;
  padding-bottom: 8rem;
}
.padding-large {
  padding-top: 12rem;
  padding-bottom: 10rem;
}
// .margin-extra-large {
//   margin-top: 12rem;
//   margin-bottom: 12rem;
// }
