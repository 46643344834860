.modal-login {
  // min-width: 24.375rem;
  width: 24.375rem;

  .modal-title {
    min-height: 3rem;
  }

  .login-subtitle {
    @include type(global-small-3);
    // margin: 3rem 0 2rem;
    margin: 0 0 2rem;
  }

  .description-forgot-password {
    margin-top: 1rem;
  }

  .description-forgot-password-confirm,
  .description-new-password {
    margin-top: 2rem;
  }

  .description-new-password-confirm {
    margin-top: 1.5rem;
  }
}
