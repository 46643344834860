.search-result {
  .product-grid-container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-auto-flow: row dense;

    .single-element {
      width: 100%;
    }

    .mobile-2 {
      grid-column: span 2;
    }
  }
}

.plp-cards-container {
  .experience-component.two-columns-component {
    padding: 0;
  }

  .two-columns-wrapper {
    box-shadow: none;
    filter: none;
  }
}

.additional_content {
  height: inherit;
  .pd-page__content {
    height: 100%;
    .experience-layouts-MMPLPCardComponent {
      box-shadow: 0 24px 96px 0 #0000000f;
      border-radius: 1.5rem;
    }
  }
}

.plp-cards-container {
  height: 100%;

  .plp-cards-container-region {
    height: 100%;

    .two-columns__media-region,
    .two-columns-wrapper,
    .two-columns-component {
      height: 100%;
    }

    .two-column-container {
      height: 100%;
    }

    .gallery__picture,
    .image-video-component,
    .experience-assets-image_video {
      height: inherit;
    }
  }

  .two-columns-component {
    .col-md-8 {
      width: 100%;
      padding: 0;
      height: inherit;
    }
  }
}

.plp-cards-container-mobile {
  &-v1 {
    &.plp-cards-container {
      .plp-cards-container-region {
        .two-columns__media-region {
          .experience-assets-image_video .image-video-component:not(.ratio):before {
            padding-top: 56.25%;
          }
        }

        .two-columns__text-region {
          height: 50%;

          .mm-button {
            margin: 0 !important;
          }
        }

        .experience-assets-image_video {
          height: 100%;
        }
      }
    }
  }
  &-v2 {
    &.plp-cards-container {
      .plp-cards-container-region {
        .two-columns__media-region {
          height: 46%;
        }

        .two-columns__text-region {
          position: relative;
          height: 54%;

          .two-columns__text-region-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
          }

          .mm-button {
            margin: 0 !important;
          }
        }

        .experience-assets-image_video {
          height: 100%;
        }
      }
    }
  }
  &-v3 {
    .two-columns-component {
      .two-columns-wrapper {
        flex-direction: row;

        .two-columns__media-region {
          width: 48%;
        }

        .two-columns__text-region {
          position: relative;
          width: 52%;

          .two-columns__text-region-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
          }

          .mm-button {
            margin: 0 !important;
          }

          .MMheadingGroup-inner {
            padding: 0 10px !important;
          }
        }
      }
    }
  }

  &-v2,
  &-v3 {
    height: var(--mobile-cards-height);
  }
}
