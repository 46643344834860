.error-bubble {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #F5EAE4;
  color: #F4502F;
  width: 100%;
  max-width: 800px;
  border-radius: 15px;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  z-index: 5;

  .error-text {
    span {
      margin-left: 8px;
    }
  }

  .close-button {
    border: 0;
    background-color: transparent;
    color: #F4502F;
    text-decoration: underline;
    text-transform: uppercase;

    &:hover {
      font-weight: 700;
    }
  }
}