.search-result {
  .product-grid-container {
    display: flex;
    grid-template-columns: none;
    grid-auto-flow: none;

    .single-element {
      width: 25%;
    }
  }
}

.plp-cards-container-v1 {
  .two-columns-component {
    .col-md-8 {
      width: 100%;
      padding-top: 150%;
      position: relative;
    }

    .two-columns-wrapper {
      flex-direction: column;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;

      .two-columns__text-region,
      .two-columns__media-region {
        width: 100%;
      }

      .two-columns__media-region {
        height: 51% !important;

        div,
        picture {
          height: 100%;
        }
      }

      .two-columns__text-region {
        position: relative;
        height: 49% !important;

        .two-columns__text-region-container {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
        }
      }
    }
  }
}

.additional_content {
  height: inherit;
  .pd-page__content {
    height: 100%;
    .experience-layouts-MMPLPCardComponent {
      height: var(--cards-height);
    }
  }
}

.plp-cards-container-v2 {
  height: 100%;

  .plp-cards-container-region {
    height: 100%;

    .two-columns__media-region,
    .two-columns-wrapper,
    .two-columns-component {
      height: 100%;
    }

    .two-columns-wrapper {
      flex-direction: row;
    }

    .two-column-container {
      height: 100%;
    }

    .gallery__picture,
    .image-video-component,
    .experience-assets-image_video {
      height: inherit;
    }
  }

  .two-columns-component {
    .col-md-8 {
      width: 100%;
      height: inherit;
    }

    .two-columns__media-region {
      width: 54% !important;
      height: 100% !important;
    }
    .two-columns__text-region {
      width: 46% !important;
      position: relative;

      .two-columns__text-region-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
    }
  }
}

.plp-cards-container-v3 {
  height: 100%;

  .plp-cards-container-region {
    height: 100%;

    .two-columns__media-region,
    .two-columns-wrapper,
    .two-columns-component {
      height: 100%;
    }

    .two-columns-wrapper {
      flex-direction: row;
    }

    .two-column-container {
      height: 100%;
    }

    .gallery__picture,
    .image-video-component,
    .experience-assets-image_video {
      height: inherit;
    }
  }

  .two-columns-component {
    .col-md-8 {
      width: 100%;
      height: inherit;
    }

    .two-columns__media-region {
      width: 50% !important;
      height: 100% !important;
    }
    .two-columns__text-region {
      position: relative;
      width: 50% !important;

      .two-columns__text-region-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
    }
  }
}

.plp-cards-container-mobile-v2,
.plp-cards-container-mobile-v3 {
  height: 100%;
}

.plp-cards-container-mobile {
  &-v1,
  &-v2 {
    &.plp-cards-container {
      .plp-cards-container-region {
        .two-columns__text-region {
          .MMheadingGroup-inner {
            padding: 0 !important;
          }
        }
      }
    }
  }
  &-v3 {
    .two-columns-component {
      .two-columns-wrapper {
        .two-columns__text-region {
          .MMheadingGroup-inner {
            padding: 0 !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .plp-cards-container-mobile-v1,
  .plp-cards-container-mobile-v2,
  .plp-cards-container-mobile-v3 {
    .two-columns-wrapper {
      .MMheadingGroup-inner {
        .type-mm-headline-2 {
          font-size: 1rem;
          margin-bottom: 0.5rem !important;
        }

        p {
          font-size: 12px;
        }
      }
    }
  }
}
