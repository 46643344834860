.mm-menu {
  position: fixed;
  width: 100%;
  top: 0;
  left: 50%;
  z-index: 10;
  transform: translate3d(-50%, 0, 0);
  &.z-index-1 {
    z-index: 1;
  }
}

.menu-is-open {
  .hide-on-open {
    display: none;
  }
}

.js-inner-menu {
  border-radius: 16px;
  background: linear-gradient(180deg, #dbdbd8 0%, #e4e4e0 100%);
}

.mm-menu__container {
  position: relative;
  padding: 1rem $menu-v-padding 0;
}
.mm-menu__wrapper {
  position: relative;
  width: 100%;
  border-radius: 1rem;
  max-height: calc(100vh - 2rem);
  // IBT-3609 The bottom of the menu background still is off-screen
  @supports (height: 100dvh) {
    max-height: calc(100dvh - 2rem);
  }
  transition: height 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  // @include backdrop-blur(1);
}

.search-is-open {
  background: var(--cru-05-transparent-60, rgba(247, 247, 242, 0.58));
  backdrop-filter: blur(100px);
  // overflow: scroll !important;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  .mm-menu-search__results__end {
    position: relative;
    width: fit-content;
    align-self: center;

    .mm-button {
      border-radius: 10px;
      border: 2px solid #1a1a1a;
      background: transparent;
      color: #1a1a1a;
      font-family: Margiela Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .js-inner-menu {
    background: transparent;
  }

  .mm-form-search-input {
    input {
      padding: 0 3rem 0 var(--search-icon-padding);
      background-position: left var(--search-input-padding) center;
    }
  }

  .mm-menu-search .mm-form-search-input + .mm-button {
    width: fit-content;
    right: unset;

    &:after {
      content: '|';
      margin-left: 5px;
      color: #0000001f;
    }
  }

  .close-label-mobile {
    display: block;
    background: none;
  }

  .arrow-icon {
    margin-right: 10px;
  }

  .close-icon {
    display: none;
  }

  .input-reset {
    right: 7px;
    position: absolute;
    background: #1a66ff14;
    width: 2.1rem;
    height: 2.1rem;
  }
}

.header-notification-slot-container {
  position: relative;

  .swiper {
    max-width: 500px;
    position: relative;
    overflow: hidden;
    width: 84%;
  }

  .swiper-wrapper {
    margin-bottom: 10px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    height: 60%;
    color: $black;

    &:after {
      font-size: 14px;
    }
  }

  .swiper-pagination-bullets {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
  }

  .swiper-pagination-bullet {
    margin: 0 2px;
    width: 5px;
    height: 5px;
  }

  .swiper-pagination-bullet-active {
    background: $black;
  }

  .mm-button {
    position: absolute;
    right: 7px;
  }
}

.html-slot-container {
  transform: translateY(0.25rem);
  padding: 0 0.25rem 0.5rem;
  transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  &.-minimized {
    transform: translateY(-100%);
    height: 0;
    padding: 0;
    overflow: hidden;
  }

  &.-closed {
    transform: translateY(-100%);
    height: 0;
    padding: 0;
    overflow: hidden;
  }

  .mm-button {
    background: none;
  }

  p {
    text-align: center;
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
}

.mm-menu__wrapper:after {
  content: '';
  position: absolute;
  width: calc(100% - 0.5rem);
  height: calc(100% - 3.25rem);
  top: 3rem;
  left: 0.25rem;
  background-color: rgba(18, 18, 18, 0.06);
  border-radius: 0.75rem;
  pointer-events: none;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.mm-menu__wrapper__inner {
  max-height: calc(100vh - 2rem);
  // IBT-3609 The bottom of the menu background still is off-screen
  @supports (height: 100dvh) {
    max-height: calc(100dvh - 2rem);
  }
}
.mm-menu__wrapper.-backdrop:after {
  opacity: 1;
}
.mm-menu__primary {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: $header-height;
}

.mm-menu__primary__start {
  > .mm-button {
    display: block;

    // IBT-4002 when you click “Menu” the “Close” text overlaps it slightly for a split second
    transition: none;
  }

  > .mm-menu__primary__list {
    display: none;
    position: fixed;
    // top: 3rem;
    width: calc(100vw - 3rem);
    border-radius: 1rem;
    height: calc(100vh - 70px);

    &.-show {
      display: inline-flex;
    }

    > ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0;
      margin: 0;

      .mm-menu__primary__item {
        > a {
          user-select: none;
          -webkit-tap-highlight-color: rgba(18, 18, 18, 0.06);
          @include type(global-control-productive-3);
        }
      }
    }
  }
}

.mm-menu__primary__end,
.mm-menu__primary__start {
  display: flex;
  flex-basis: 50%;
  align-items: center;
  width: 25%;
}

.mm-menu__primary__end {
  justify-content: flex-end;
  li {
    position: relative;
    margin: 0;

    a {
      height: 100%;
    }

    .minicart-modal {
      a {
        height: 2.5rem;
      }
    }
  }

  .mm-menu__primary__list {
    place-content: flex-end;
    width: 100% !important;

    .js-menu-open {
      padding: 0;
      margin: 0 auto;
    }

    .js-button-wishlist,
    .js-button-minicart {
      padding: 0 10px !important;

      &.-dot-info::after {
        width: 0.5rem;
        height: 0.5rem;
      }
    }

    .js-button-wishlist {
      .mm-icon {
        height: 1.2rem;
        width: 1.2rem;
      }
    }
  }
}

.mm-menu__primary__list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-flex;
}

.mm-menu__primary__list li {
  padding: 0;
  margin: 0;
  display: inline-block;
  white-space: nowrap;
}

.mm-menu__secondary {
  display: none;
  // position: relative;
  padding: 0.25rem 0.5rem 0.5rem;
  z-index: 1;
  max-height: calc(100vh - 5rem);
  // overflow: scroll;
  position: absolute;
  width: calc(100vw - 32px);
  left: 50%;
  border-radius: 1rem;
  transform: translateX(-50%);
}

.mm-menu__secondary li + li {
  margin-top: 2px;
}

.mm-menu__secondary__start {
  position: -webkit-sticky;
  position: sticky;
  width: 10.75rem;
  top: 0;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.mm-menu__secondary__end {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.mm-menu__secondary__col {
  width: fit-content;
  flex: 0 0 auto;
  margin: 0 0.25rem;
  padding-bottom: 2rem;
}

.mm-menu__secondary__col-content {
  flex-grow: 1;
}

.mm-menu__secondary__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.mm-menu__secondary__list li {
  padding: 0;
  margin: 0;
}
.mm-menu__secondary__list__title {
  display: flex;
  align-items: center;
  height: 2rem;
  margin-bottom: 0.125rem;
  padding: 0 0.8125rem;
  @include type(global-control-productive-3);
  font-weight: 700;

  &.-active {
    background-color: rgba(18, 18, 18, 0.06);
    border-radius: 0.625rem;
  }

  h3 {
    font-weight: 700 !important;
  }
}

.mm-menu__secondary__list li {
  display: block;
}
.mm-menu__secondary__list li + li {
  margin-top: 0.125rem;
}
.mm-menu__secondary__tabs {
  padding: 0;
  margin: 0;
  list-style: none;
}
.mm-menu__secondary__tabs li {
  padding: 0;
  margin: 0;
}

// mobile menu

.mm-menu-mobile {
  position: fixed;
  width: 100%;
  top: 0;
  left: 50%;
  z-index: 1030;
  transform: translate3d(-50%, 0, 0);
}
.mm-menu-mobile__container {
  position: relative;
  padding: 1rem 1rem 0;
}
.mm-menu-mobile__wrapper {
  position: relative;
  width: 100%;
  height: 3.5rem;
  max-height: calc(100vh - 2rem);
  max-height: calc(var(--initial-vh, 1vh) * 100 - 2rem);
  border-radius: 1rem;
  overflow: hidden;
  transition: height 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transform: translateZ(0);
  @include backdrop-blur(1);
}

.mm-menu-mobile__wrapper__inner {
  position: relative;
  width: 100%;
  height: calc(100vh - 2rem);
  height: calc(var(--initial-vh, 1vh) * 100 - 2rem);
  display: flex;
  flex-direction: column;
}
.mm-menu-mobile__primary {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 3.5rem;
}
.mm-menu-mobile__primary__end,
.mm-menu-mobile__primary__start {
  display: flex;
  flex-basis: 50%;
  padding: 0.25rem;
}
.mm-menu-mobile__primary__end {
  justify-content: flex-end;
}
.mm-menu-mobile__secondary {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-direction: column;
  padding: 0 0.25rem;
  display: none;

  &.-show {
    display: flex;
    height: calc(var(--initial-vh, 1vh) * 100 - 5.5rem);
    @supports (height: 100dvh) {
      // IBT-3609 the bottom of the menu is cut off
      // .mm-menu-mobile__wrapper {
      max-height: calc(100dvh - 5.5rem);
      // }
    }
  }
}
.mm-menu-mobile__secondary__start {
  position: relative;
  flex-grow: 1;
  height: 100%;

  .js-search-btn-primary {
    border: 1px solid $ecru-50;
    background: transparent;
  }

  .js-controls-navigation,
  .new-switch-selector,
  .mm-switch {
    margin: 10px 0;
  }

  .js-controls-navigation {
    .js-search-btn {
      opacity: 0;
    }
  }
}
.mm-menu-mobile__secondary__end {
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0 0;
}
.mm-menu-mobile__view {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.mm-menu-mobile__view__start {
  flex-shrink: 1;
}
.mm-menu-mobile__view__end {
  position: relative;
  flex-grow: 1;
  height: 100%;
}

.mm-menu-mobile__list {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 0.875rem;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.mm-menu-mobile__view__end {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.menu-pd-content-container {
  .pd-whiteSpace,
  .experience-component {
    background-color: transparent;

    li:before {
      border: none !important;
    }
  }

  a {
    height: 100% !important;
  }

  .carousel-one-slide,
  .carousel-vertical {
    .experience-region {
      height: inherit;

      div {
        height: inherit;
      }
    }
  }

  .experience-layouts-MMCarousel .swiper-pagination {
    margin: 0.5rem auto;
    .swiper-pagination-bullet {
      width: 4px;
      height: 4px;
      margin: 0 4px !important;
      flex-grow: unset;
      border-radius: 99px;
    }
  }

  .image-video-component {
    height: 100%;

    picture {
      height: 180px;
    }
  }

  .circular-banners {
    .image-video-component {
      height: 100%;

      picture {
        height: inherit !important;
      }
    }
  }

  .-focus {
    border-radius: 74px;
    border: 2px solid #1966ff;
    box-shadow: 0px 0px 0px 4px rgba(31, 105, 255, 0.17);
  }

  .image-content-title {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #767674;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 8px 0;
    position:absolute;
    bottom:-30px;
    left:0;
    right:0;
    height:auto !important;
  }

  .circular-banners .image-content-title {
    position: initial;
    width: 74px;
  }

  .carousel-one-slide,
  .carousel-vertical {
    .image-content-title {
      width: 100%;
      color: #767674;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      margin: 8px 0;

      a {
        color: #767674;

        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .carousel-one-slide {
    .image-video-component {
      width: 100%;
    }
  }

  .carousel-vertical {
    .image-content-title {
      text-align: center;
    }
  }
}

.circular-banners {
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin: 0 30px;

  &::-webkit-scrollbar {
    display: none;
  }

  .menu-content-container-region {
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    width: fit-content;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .experience-assets-image_video {
      margin-right: 16px;

      .image-video-component {
        width: 74px;
        height: 74px;
      }
    }

    .image-video-component {
      border-radius: 100%;
    }
  }

  .menu-content-container-region::-webkit-scrollbar {
    display: none;
  }
}

.mm-menu-mobile__list .js-header-menu-brand-mm6,
.mm-menu-mobile__list .js-header-menu-brand-mm,
.mm-menu-mobile__list .mm-menu-mobile__secondary__list,
.mm-menu-mobile__list .mm-menu-mobile__meta ul {
  margin: 0;
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 2.5rem 0;
  position: relative;

  li:before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 50px;
    border-bottom: 0.5px solid #767674;
    left: 50%;
    transform: translate(-50%);
  }

  li:last-child {
    &:before {
      content: '';
      border-bottom: none;
    }
  }
}

.mm-menu-mobile__list ul li {
  margin: 0;
  width: 100%;
  display: block;
  padding: 0.125rem 0;
  position: relative;
}

.mm-menu-mobile__list ul li a,
.mm-menu-mobile__list ul li button {
  background-color: transparent;
  width: 100%;
  height: 4rem;
  @include type(global-control-productive-3);
  font-size: 1rem;
}

.mm-menu-mobile__list ul li button:active,
.mm-menu-mobile__list ul li button:focus,
.mm-menu-mobile__list ul li button:hover,
.mm-menu-mobile__list ul li button:visited {
  color: inherit;
}
@media (min-width: 768px) {
  .mm-menu-mobile__list ul li button {
    font-size: 0.875rem;
  }
}
.mm-menu-mobile__meta {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  white-space: nowrap;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ul {
    li {
      margin: 5px 0;

      button,
      a {
        height: fit-content;
        font-size: 13px;

        span {
          font-size: 13px;
        }
      }

      &:before {
        border-bottom: none !important;
      }
    }
  }
}
.mm-menu-mobile__meta::-webkit-scrollbar {
  display: none;
}
.mm-menu-mobile__meta ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-flex;
}
.mm-menu-mobile__meta ul li a {
  @include type(global-control-productive-3);
  font-size: 13px;
  text-decoration: none;
  color: inherit;
}
@media (min-width: 768px) {
  .mm-menu-mobile__meta ul li a {
    font-size: 0.875rem;
  }
}
// .mm-menu-mobile .slide-ltr-enter-active,
// .mm-menu-mobile .slide-ltr-leave-active,
// .mm-menu-mobile .slide-rtl-enter-active,
// .mm-menu-mobile .slide-rtl-leave-active {
//   overflow: hidden;
//   transition: transform 0.7s cubic-bezier(0.16, 1, 0.3, 1);
// }
// .mm-menu-mobile .slide-rtl-enter {
//   transform: translate(100%);
// }
// .mm-menu-mobile .slide-ltr-enter,
// .mm-menu-mobile .slide-rtl-leave-to {
//   transform: translate(-100%);
// }
// .mm-menu-mobile .slide-ltr-leave-to {
//   transform: translate(100%);
// }
.mm-menu-mobile.-open .mm-menu-mobile__wrapper {
  background-color: rgba(247, 247, 242, 0.5);
}

.mm-menu-mobile__list .mm-menu-mobile__secondary__list {
  overflow: hidden;
  position: absolute;
  top: 0;
  // left: 100%;
  transition: transform 0.7s cubic-bezier(0.16, 1, 0.3, 1);

  // &.-active {
  //   left: 0;
  // }

  .mm-menu-item {
    margin: 0;
    width: 100%;
    display: block;
    padding: 0.125rem 0;

    > a {
      padding: 0;
      font-style: normal;
      cursor: pointer;
      background-color: transparent;
      border: 0;
      outline: 0;
      appearance: none;
      width: 100%;
      height: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1;
      text-decoration: none;
      color: inherit;
      border-radius: 0.875rem;
      user-select: none;
      -webkit-tap-highlight-color: rgba(18, 18, 18, 0.06);
    }
  }
}

.notification-close-button {
  svg {
    width: 20px;
  }
}

.mm-menu__primary__start {
  display: flex;
  flex-direction: row;
  position: relative;

  .show-mobile {
    margin: 0;

    button {
      height: 48px;
      width: 48px;
      padding: 0 10px;
    }
  }
}

.mm-menu__primary__center {
  width: 50%;

  .mm-menu-logo {
    margin: 0 auto;
  }
}

.menu-item-container {
  display: flex;
  flex-direction: row;
}

.main-section.-active {
  background-color: rgba(18, 18, 18, 0.06);
}
.mm-menu__primary__list {
  width: fit-content !important;
}

.menu-open {
  flex-direction: column;
  transform: translateY(0);
  transition: transform ease-in-out 0.4s, opacity ease-in-out 0.5s;
  width: 100% !important;
  .menu-title {
    display: none;
  }

  .closed {
    display: none;
  }

  .menu-item {
    width: 100%;
    margin: 0;
  }

  .submenu {
    position: relative;
    opacity: 1;
    border-radius: 16px;
    background: #cfcfcb;
    margin: 0.25rem 0.25rem 0.25rem;
    width: auto;
  }
}

.other-menu {
  height: 3rem;

  .mm-menu__secondary__tabs {
    margin-left: 10px;
  }
}

.parent-menu {
  margin-bottom: 20px;
}

.parent-menu,
.other-menu {
  display: flex;
  flex-direction: row;
  pointer-events: auto;

  .mm-menu__secondary__tabs {
    display: flex;
    flex-direction: row;
  }
}

.brand-item,
.menu-item {
  width: fit-content;
  align-self: center;
}

.hidden {
  display: none !important;
  opacity: 0;
}

.submenu {
  width: 100%;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateY(-100%);
}

.submenu-content {
  pointer-events: auto;
}

#wechat-app-modal {
  .js-modal-container {
    max-width: 300px;
  }
}
