.form-chatbox-input {
  .mm-form-fieldset {
    margin-top: 12px;
  }

  .mm-form-input:not(.-invalid) {
    position: relative;

    input:not(:placeholder-shown):valid,
    input:focus,
    input:hover:focus,
    input:hover,
    input {
      background-image: none;
    }

    .input-action-container {
      position: absolute;
      top: 0;
      right: 10px;
      display: flex;
      height: 48px;
      align-items: center;

      .char-limit {
        color: rgba(18, 18, 18, 0.3);
        font-size: 14px;
        pointer-events: none;

        &.limit-reached {
          font-weight: 700;
        }
      }

      .mm-button.send-message-btn {
        background: transparent;
        width: 44px;
        height: fit-content;
        padding: 0;
        margin-left: 10px;

        &:before {
          width: 24px;
          height: 24px;
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-opacity='0.3' stroke-width='1.5' d='M22 2L13.5 10.5M22 2L15 21L11 13L3 9L22 2Z'/%3E%3C/svg%3E%0A");
        }
      }
    }

    &:hover {
      .mm-button.send-message-btn {
        &:before {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-opacity='0.4' stroke-width='1.5' d='M22 2L13.5 10.5M22 2L15 21L11 13L3 9L22 2Z'/%3E%3C/svg%3E%0A");
        }
      }
    }

    input:not(:placeholder-shown):valid + .input-action-container .mm-button.send-message-btn {
      background-color: #1f69ff;
      border-radius: 15px;
      &:before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M22 2L13.5 10.5M22 2L15 21L11 13L3 9L22 2Z' stroke='white' stroke-width='1.125' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      }
    }

    input:not(:placeholder-shown):valid + .input-action-container .mm-button.send-message-btn:visited {
      background-color: #0054d1;
      border-radius: 15px;
      &:before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M22 2L13.5 10.5M22 2L15 21L11 13L3 9L22 2Z' stroke='white' stroke-width='1.125' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      }
    }

    input:focus + .input-action-container .mm-button.send-message-btn {
      &:before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M22 2L13.5 10.5M22 2L15 21L11 13L3 9L22 2Z'/%3E%3C/svg%3E%0A");
      }
    }
  }

  .mm-form-input input {
    border: 1px solid rgba(18, 18, 18, 0.3);

    &:hover {
      border: 2px solid rgba(18, 18, 18, 0.3);
    }

    &:focus {
      border: 1px solid #1f69ff;
    }
  }

  .chatbox-input {
    padding-right: 130px;
  }

  max-width: 640px;
  margin: 0 auto;

  .disclaimer {
    font-size: 8px;
    text-align: center;
    font-family: 'Margiela Serif';

    .link {
      color: #1f69ff;
      text-decoration: underline;
    }
  }
}

.start-again-container {
  margin-top: 10px;

  .mm-button {
    font-family: 'Margiela Sans';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;

    &.expired-restart {
      color: #fff;
      background-color: #1a1a1a;
      width: 100%;
      font-size: 14px;
    }
  }
}
