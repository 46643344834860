.experience-block,
.experience-component {
  &[data-animation='reveal'] {
    .image-video-component {
      video {
        // transform: scale(1.2, 1.2) translateX(-50%);
        transform: translateX(-50%) !important;
      }
    }
  }
}
