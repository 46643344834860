$form-select-chevron-grey: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMTQgNS4zMzMzMUw4IDExLjMzMzNMMiA1LjMzMzMxIiBzdHJva2U9IiNjMWMxYmQiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+');

.mm-form-select {
  select {
    &:invalid {
      color: $ecru-30;
      background-image: $form-select-chevron-grey;
    }
  }
}
