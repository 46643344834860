.main-footer {
  padding: 15px 0 40px;
  .title-section-footer,
  .button-open-menu-footer {
    text-transform: uppercase;
    font-size: 0.813rem;
  }
  .button-open-menu-footer {
    width: 100%;
    justify-content: space-between;
    .button-icon {
      width: 14px;
      height: 12px;
      transition: 0.3s;
    }
  }
  .container-list-footer {
    margin-top: 25px;
    ul {
      list-style: none;
      padding: 0;
      li {
        margin-bottom: 32px;
        a {
          text-transform: none;
          text-decoration: none;
          font-weight: 400;
          font-size: 0.813rem;
        }
      }
    }
  }
  .section-footer-box {
    padding: 17px 15px;
  }
  .second-section-footer {
    margin-top: 25px;
    text-align: center;
    .text-subtitle-footer {
      margin: 16px 0 16px 0;
      a {
        text-decoration: none;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        display: inline-block;
        padding: 0 12px;
        a {
          text-transform: uppercase;
        }
      }
    }
  }

  .editorial-footer {
    display: inline-flex;
    flex-direction: column;
    > .section-footer-box {
      width: 100%;
      display: contents;
      justify-content: center;
    }
  }

  .container-social {
    padding-left: 15px;
    padding-right: 15px;
  }

  .btn-country-selector {
    display: block;
    margin-bottom: 15px;
  }

  .section-newsletter-footer {
    text-align: center;

    .section-newsletter-title {
      @include type(global-control-expressive-3);
    }
    .section-newsletter-subtitle {
      @include type(global-control-productive-3);
    }
  }

  .bottom-section-footer {
    text-align: center;
  }

  .bottom-section-footer {
    margin-top: 3.75rem;
    margin-bottom: 2.74rem;
    @include type(global-caption-2);
    a {
      color: get-color('ecru-70');
    }
  }

  .section-footer-box,
  .footer-links {
    @include type(global-control-expressive-1);
    button,
    a {
      @include type(global-control-expressive-1);
    }
  }

  .link-shipping-to {
    .current-locale-country {
      margin-left: 0.5rem;
    }
    a.country-selector-footer {
      height: 2rem;
      padding: 0.4rem;
    }
  }

  .footer-links {
    a.mm-button {
      background-color: get-color('ecru-95', 20);
      margin-top: 20px;
    }
  }

  .footer-site-selector {
    padding: 57px 0 20px 0;
    font-weight: bold;
  }

  .footer-middle-section {
    text-align: center;
  }

  .footer-site-selector {
    text-align: center;
    padding: 57px 0 20px 0;
    font-weight: bold;
  }

  .js-accordion-trigger.mm-button.-ghost:not(:disabled):active {
    background-color: transparent;
  }

  .socials {
    margin-top: 20px;
    margin-left: 10px;

    .section-icon-social {
      margin-top: 20px;

      a[class*='icon--'] {
        width: 2rem;
        height: 2rem;
        display: inline-block;
        margin-right: 0.5rem;
      }
    }
  }
}
