.accordion-mini__header {
  position: relative;
  text-decoration: none;
  color: $black;
  padding-right: 2.4rem;
  width: 100%;

  &::after {
    content: '';
    transform: translateY(-50%);
    position: absolute;
    right: 0;
    top: 50%;
  }

  &[aria-expanded='false'] {
    & + .accordion-mini__panel {
      height: 0;
      visibility: hidden;
    }
  }

  &[aria-expanded='true'] {
    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.accordion-mini__panel {
  overflow: hidden;
  visibility: visible;
}
