@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateX(50%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-15%);
  }
}

.modal {
  display: none;
  &.is-open {
    display: block;
  }

  &[aria-hidden='false'] {
    .modal-overlay {
      animation: mmfadeIn 0.4s cubic-bezier(0, 0, 0.2, 1);
    }
    .modal-container {
      animation: mmfadeIn 0.4s cubic-bezier(0, 0, 0.2, 1);
    }
  }

  &[aria-hidden='true'] {
    .modal-overlay {
      animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
    .modal-container {
      animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
  }
}

.modal-overlay {
  background: get-color('black', 50);
  // background: $ecru-95-50;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: transform;
  z-index: 15;
}

.modal-container {
  will-change: transform;
  background-color: get-color('ecru-05');
  border-top-left-radius: $border-radius-12;
  border-top-right-radius: $border-radius-12;
  box-sizing: border-box;
  height: calc(100vh - 1.75rem);
  max-height: calc(100vh - 1.75rem);
  max-width: 100%;
  overflow-y: auto;

  > div {
    position: relative;
    height: 100%;
  }
}

.modal-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 2;
  &.mm-button {
    height: rem(40);
    width: rem(40);
    .mm-icon {
      height: 1.125rem;
      width: 1.125rem;
    }
  }
}

.modal-header {
  padding: 1rem rem(28);
}

.modal-content {
  padding: 1rem;
  overflow-y: auto;

  p {
    @include type(global-small-3);
  }
}

.modal-title {
  @include type(global-callout-2);
  // padding-left: 0.5rem;
  padding-right: 3rem;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
}
