#login-modal,
#forgot-password,
#new-password {
  .modal-close {
    height: 2rem;
    width: 2rem !important;
    right: 0.75rem;
    top: 0.75rem;
  }
}

#login-modal {
  .modal-container {
    min-height: 32.5rem;
  }
}

.modal-login {
  .modal-header {
    padding-top: 1.2rem;
  }

  .modal-title {
    min-height: auto;
  }

  .modal-content {
    // margin-bottom: 3rem;
    margin-bottom: 5rem;
  }

  [type='submit'] {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    // margin-bottom: 8.25rem;
    margin-top: 1rem;
  }
}
