.section-newletter-button {
  margin-top: 2rem;
}

.section-newsletter-footer {
  max-width: unset;
  form {
    padding: 0 2rem 2rem;
  }

  .form-newsletter {
    max-width: 380px;
    margin: 0 auto;
  }
}

.newsletter-journey-component {
  height: 35rem;

  &.thank-you {
    height: 35rem;
  }

  .section-newsletter-text {
    margin-top: 2rem;
    @include type(global-small-2);
  }
}

.newsletter-journey-plp {
  .two-columns-wrapper {
    height: 27rem;
  }

  .newsletter-journey-component {
    height: 25rem;

    .newsletter-close-button {
      display: flex;
      position: absolute;
      width: 2rem;
      height: 2rem;
      right: 0.5rem;
      top: 0.5rem;
      background-color: transparent;
    }

    .newsletter-section {
      padding: 1rem;
      margin-bottom: 4rem;
    }

    &.thank-you {
      height: 25rem;
    }
  }
}

.newsletter-heading {
  margin-top: 4rem;
}

.newsletter-headline {
  margin-top: 4rem;
}
