.two-columns-component {
  .two-columns-wrapper {
    flex-direction: column;
    .two-columns__media-region {
      order: 1;
    }
    .two-columns__text-region {
      order: 2;
    }
  }
}

.packaging-journey,
.newsletter-journey-plp {
  .image-video-component {
    height: 15rem;
  }
}
