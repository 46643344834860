.experience-layouts-MMbreadcrumbs {
  .breadcrumb-component {
    justify-content: center;
  }
  .editorial-breadcrumbs {
    .breadcrumb-item {
      &:last-of-type {
        a {
          color: $ecru-95;
        }
        .breadcrumb-separator {
          display: none;
        }
      }
    }
  }
}
