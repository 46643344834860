.menu-close {
  position: absolute;
  top: -3.25rem;
}

.mm-menu-mobile__controls {
  background: rgba(18, 18, 18, 0.06);
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  border-radius: 0.875rem;
  padding: 0.25rem 0.5rem;
  display: none;
  &.-show {
    display: flex;
  }
}
.mm-menu-mobile__controls button {
  padding: 0;
  font-family: inherit;
  font-style: normal;
  font-weight: inherit;
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  appearance: none;
}
.mm-menu-mobile__controls button:active,
.mm-menu-mobile__controls button:visited {
  color: inherit;
}
.mm-menu-mobile__controls__breadcrumb span {
  display: inline-block;
  @include type(global-control-productive-3);
  font-size: 1rem;
  cursor: pointer;
}

.mm-menu-mobile__controls__breadcrumb span:not(:last-child) {
  opacity: 0.3;
}
.mm-menu-mobile__controls__breadcrumb span:not(:last-child):after {
  content: '/';
  margin: 0 1ch;
}

.mm-menu-mobile__controls__breadcrumb .top-label.unclickable {
  pointer-events: none;
  cursor: default;
}

.mm-menu-feature {
  position: relative;
  margin-top: 2rem;
  height: 100% !important;
}

.mm-menu-feature__title {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 1;
  z-index: 1;
}

.mm-menu-feature__image {
  height: auto;
  width: 100%;
}

.mm-menu__primary__start {
  > .mm-menu__primary__list {
    > ul {
      .mm-menu__primary__item {
        > a {
          background-color: transparent;
        }
      }
    }
  }
}

.mm-menu-mobile__view__end {
  overflow-x: hidden;
  overflow-y: auto;
}

.mm-menu-mobile__list {
  top: 0;
}

.mm-menu-mobile__level-1,
.mm-menu-mobile__level-2,
.mm-menu-mobile__level-3 {
  display: none;
  left: 0;
  &.is-open {
    display: block;
  }
}
//
.mm-menu-mobile__list {
  overflow: visible;
}

.slide-transition {
  transition: transform 700ms cubic-bezier(0.16, 1, 0.3, 1);
}

.slide-ltr {
  transform: translate(0%, 0);
}

.slide-rtl-enter {
  transform: translate(100%, 0);
}

.slide-rtl-leave-to {
  transform: translate(-100%, 0);
}

.slide-ltr-enter {
  transform: translate(-100%, 0);
}

.slide-ltr-leave-to {
  transform: translate(100%, 0);
}

#wechat-app-modal {
  .modal-overlay {
    justify-content: center;
  }
  .js-modal-container {
    height: auto;
    max-width: 15rem;
    margin: auto;
    overflow: auto;
    border-radius: 1.5rem;

  }
}