.mm-switch {
  &__toggle {
    label {
      cursor: pointer;
    }
    input {
      display: none;
    }
  }
}
