.chat-bubble {
  flex-wrap: wrap;

  .bot-profilepic {
    position: relative;
    top: unset;
  }

  &.bot {
    .message {
      width: 100%;
      margin-left: 0;
    }

    &.chips {
      margin-bottom: 10px;
      .message {
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;

        .chip {
          margin-bottom: 5px;
        }

        &.login-form {
          form.reset-password-form {
            .recaptcha-subtext {
              font-size: 8px;
            }
          }
        }
      }
    }

    &.products {
      max-width: 100%;

      .message {
        padding-top: 0;

        .swiper-wrapper {
          .product-chatbot-tile {
            max-width: 140px;
          }
        }
      }
    }
  }

  &.sender {
    .message {
      margin-top: 0;
    }
  }
}