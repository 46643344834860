.layer-logged {
  display: none;
  position: absolute;
  top: calc(100% + 0.5rem);
  left: auto;
  right: 0;
  padding: 0.25rem;
  min-width: 11.25rem;
  border-radius: $border-radius-8;
  background-color: rgba(247, 247, 242, 0.24);
  backdrop-filter: blur(100px);
  &.-show {
    display: block;
  }

  ul {
    display: flex;
    flex-direction: column;

    .mm-button {
      justify-content: flex-start;
    }

    li + li {
      margin-left: 0;
    }

    li {
      &:last-of-type {
        margin-top: 1px;
        &:before {
          content: '';
          height: 1px;
          width: calc(100% - 1rem);
          background-color: rgba(var(--mm-ecru-95-rgb), 0.06);
          position: absolute;
          left: 0.5rem;
          top: -1px;
        }
      }
    }
  }
}
