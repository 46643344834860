.checkoutpage {
  &.payment {
    .jp-checkout {
      .cvv-expiration-fields.noborder .adyen-checkout__card__cvc__input {
        width: rem(200);
      }
      .cvv-expiration-fields .adyen-checkout-input__inline-validation {
        right: rem(16);
      }
      .adyen-checkout__card__exp-cvc {
        .adyen-checkout__field--50 {
          &.adyen-checkout__field--expiryDate {
            width: 33%;
          }
          &.adyen-checkout__field--securityCode {
            width: 67%;
          }
        }
      }
    }

    .checkout-payment {
      // TODO remove unnecessary properties
      color: #111111;
      font-family: 'Margiela Sans';
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;

      button:not(.mm-button) {
        @include button-reset();
      }

      h2 {
        @include type(global-control-expressive-3);
        margin-bottom: 42px;
        text-transform: uppercase;
      }

      form {
        > .form-group {
          margin-top: 35px;
        }
      }

      &-subtitle {
        display: none;
      }
    }

    .billingAddressForm {
      overflow: hidden;
    }

    .billingAddressForm-checkbox {
      input {
        align-self: center;
      }

      label {
        width: 200px;
      }
    }

    .addresses {
      margin-top: 15px;

      .col-11 {
        width: 100%;
      }
    }

    .checkout-billing__address__address-book__new {
      margin-top: rem(35px);
    }
    .add-to-address {
      margin-left: 5px;
    }

    .card-type-icon,
    [class^='icon--pay-'] {
      width: 35px;
      height: 24px;
    }

    .payment-method {
      display: flex;
      position: relative;

      &:not(:first-child) {
        margin-top: 45px;
      }

      > input {
        left: 0;
        margin: 0;
        position: absolute;
        top: 0;
      }

      > label {
        display: flex;
        flex: 1;
      }

      &__info {
        font-size: 13px;
        color: #858583;
        margin: 15px 30px 0 5px;
        line-height: 160%;
      }
    }

    .container-radio-payment {
      .radio__icon {
        &.credit-card {
          .single-payment-box {
            > .row {
              .col-9 {
                margin-top: 28px;
              }
            }
          }
        }

        &:not(.credit-card) {
          .single-payment-box {
            > .row {
              align-items: center;
              .col-9 {
                flex-basis: 25%;
                max-width: 25%;
              }
              .col-3 {
                flex-basis: 75%;
                max-width: 75%;
              }
            }
          }

          .single-payment-box-card-type {
            justify-content: flex-end;

            .col {
              padding-right: 12px;
            }
          }
        }

        .single-payment-box {
          > .row {
            .col-3 {
              flex-basis: 75%;
              max-width: 75%;
              align-items: center;
              display: flex;
              height: 24px;
            }
          }

          p {
            margin-left: 24px;
            margin-bottom: 0;
          }
        }

        .single-payment-box-card-type {
          .col {
            flex-grow: 0;
            padding-left: 2px;
          }
        }
      }
    }

    .radio-container {
      border: 2px solid rgba(17, 17, 17, 0.06);
      border-radius: 15px;
      margin: 20px -12px 0;
      padding: 15px 19px;
      width: calc(100% + 24px);
    }

    #addressbookid {
      .radio-container {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
    }

    .sub-radio__element {
      &::before {
        content: '';
        position: absolute;

        width: $form-check-size-lg;
        height: $form-check-size-lg;
        vertical-align: middle;
        cursor: pointer;
        border: 2px solid get-color('ecru-95', 10);
        border-radius: $border-radius-full;
        box-shadow: 0 0 0 4px transparent;
      }

      &-img {
        display: none;
      }

      &-card-details {
        > div {
          &:not(:first-child) {
            color: #858583;
            font-size: 12px;
            letter-spacing: 0.1px;
          }

          p {
            margin-bottom: 0;
          }
        }

        &-holder {
          align-items: center;
          display: flex;
          height: 24px;
          margin-bottom: 1.2rem;
          padding-left: 32px;
        }

        &-expiration {
          margin-bottom: 0.5rem;
        }
      }
    }

    .radio-item-checked .sub-radio__element::before {
      background-color: get-color('info-50');
      background-image: $form-check-icon-lg;
      border-color: transparent;
    }

    .payment-option-buttons {
      margin: 30px 0 15px;

      .button {
        display: flex;
        gap: 0.5rem;
      }
    }

    .enter-credit-card-information {
      font-size: 15px;
      margin-bottom: 20px;
      padding: 0 5px;
    }

    .adyen-checkout {
      &__label {
        position: relative;
        width: 100%;
      }

      &__label__text,
      &__dropdown__button__text {
        position: absolute;
        top: 0;
        left: 1rem;
        background-color: $ecru-05;
        color: get-color('ecru-95', 30);
        display: none;
        padding: 0 0.1875rem;
        transform: translate3d(0, -50%, 0);
        z-index: 2;
      }

      &__label--focused .adyen-checkout__label__text,
      &__dropdown__button--active .adyen-checkout__dropdown__button__text {
        color: $info-50;
        display: block;
      }

      &__input {
        background: transparent;
        border: 0.125rem get-color('ecru-95', 10) solid;
        border-radius: $border-radius-7;
        height: 45px;
        padding: 5px 17px;

        &:focus {
          border-color: $info-50;
          outline: 0;
          box-shadow: 0 0 0 4px get-color('info-50', 20);
        }

        > img,
        & + div:not(.adyen-checkout__card__dual-branding__buttons) {
          display: none;
        }
      }

      &__checkbox {
        align-items: center;
        flex-direction: row;

        &__input {
          // align-self: center;
          width: $form-check-size-lg;
          height: $form-check-size-lg;
          vertical-align: middle;
          cursor: pointer;
          border: 2px solid get-color('ecru-95', 10);
          border-radius: $form-check-size-lg-radius;
          box-shadow: 0 0 0 4px transparent;
          appearance: none;
          opacity: 1;

          &:checked {
            background-color: get-color('info-50');
            background-image: $form-check-icon-lg;
            border-color: transparent;
          }
        }

        &__label {
          font-size: 15px;
          letter-spacing: 0.1px;
          line-height: 160%;
          margin-left: 15px;

          &::after,
          &::before {
            display: none;
          }
        }
      }

      &__store-details {
        margin-left: 7px;
      }

      &__field--storedCard {
        &:not(.adyen-checkout__field--securityCode) {
          display: none;
        }
      }

      &__dropdown {
        width: 100%;
        height: 3rem;
        padding: 0 3rem 0 1rem;
        color: $ecru-95;
        cursor: pointer;
        user-select: none;
        background-color: $ecru-05;
        background-image: $form-select-chevron;
        background-repeat: no-repeat;
        background-position: right 1rem center;
        background-size: 1rem 1rem;
        border: 0.125rem get-color('ecru-95', 10) solid;
        border-radius: $border-radius-7;
        box-shadow: 0 0 0 4px transparent;
        @include type('global-control-productive-3');

        &__button {
          &--active,
          &--active:hover,
          &:active,
          &:focus {
            box-shadow: none;
          }

          &::after {
            display: none;
          }
        }
      }
    }

    .cvv-expiration-fields {
      border: 2px solid rgba(17, 17, 17, 0.06);
      border-radius: 15px;
      // margin: 30px -12px 0 -12px;
      margin-top: 30px;
      padding: 15px;

      > p {
        display: block;
      }

      &.nodisplay {
        display: none;
      }

      &.noborder {
        border: 0;
        padding: 0%;

        > p {
          display: none;
        }
        .adyen-checkout__field-wrapper > .adyen-checkout__field:nth-child(2) {
          margin-left: 0;
        }
        /*
        .adyen-checkout__field__cvc {
          .adyen-checkout__label__text {
            left: 0.35rem;
          }
        }
        */

        .adyen-checkout__card__cvc__input {
          // width: 75px;
          // @@@@@@@@@@@@@
          width: 155px;
        }
      }
      .adyen-checkout-input__inline-validation {
        position: absolute;
        right: 3.25rem;
        top: 1.2rem;
      }
    }

    .paymentmethods + .cvv-expiration-fields {
      margin-top: 60px;
    }

    /*
    .payment-method.credit-card {
      > .input-radio:checked + label {
        .cvv-expiration-fields {
          display: block;
        }
      }
    }
    */

    .proceed-to-review {
      .navigations-buttons {
        padding: 0 0.5rem;

        .checkout-navigations__steps {
          display: none;
        }

        button {
          height: 3rem;
          margin: 0 -12px;
          width: calc(100% + 24px);
        }
      }
    }
  }
  .not-pickup-only-checkout {
    #shipping-data-accordion-content {
      .mm-form-select {
        #country {
          background-image: none;
        }
      }
    }
  }
  .mm-form-select select {
    font-size: 0.875rem;
    line-height: 1.5;
  }

  #checkout-recap-bottom-payments svg {
    margin-right: 6px;
  }
}

// .mm-form-select {
//   select {
//     & ~ label {
//       display: none;
//     }

//     &:focus ~ label {
//       display: block;
//     }
//   }
// }
