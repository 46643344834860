.grid-gallery-container {
  padding-top: 4.5rem;
}

.grid-gallery-component {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 0.5rem;
  row-gap: 3rem;

  .gallery-index {
    text-align: center;
    @include type(global-control-expressive-1);
    margin: 1rem 0;
  }

  &.rounded-corners {
  }
  &.squared-corners {
    .image-video-component {
      border-radius: 0;
    }
  }
}

.gallery-actions {
  display: flex;
  align-items: center;
  flex-direction: column;

  .gallery-counter {
    @include type(global-control-expressive-1);
    margin-bottom: 1rem;
  }
}
