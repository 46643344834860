#chatbot-app {
  margin: 0 15px;
}

.chat-container {
  height: 60vh;
  background-color: rgba(17, 17, 17, 0.06);
  border-radius: 15px;
  padding: 0 30px 10px;

  .bubble-container {
    display: flex;
    flex-direction: column;
    justify-content: end;
    min-height: 58vh;
  }

  .expired-session {
    width: 100%;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 10px;

    .expire-from-history,
    .subtext {
      font-family: "Margiela Serif";
      font-size: 14px;
    }

    .thanks {
      font-family: "Margiela Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
    }
  }
}

.chatbot-breadcrumbs {
  width: 100%;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 100px;

  .breadcrumb {
    display: inline-flex;
    margin: 0 20px;
    text-transform: uppercase;
    font-family: "Margiela Sans";
    font-size: 12px;
    font-weight: 700;
    color: rgba(18, 18, 18, 0.60);
  }

  .separator {
    color: #000;
  }
}
