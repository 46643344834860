.wrapper-pdp {
  .bopis-btn {
    background: $ecru-05;
    border-radius: 1rem;
    font-size: 14px;
    height: 3rem;
    line-height: 17px;

    &:hover {
      background: get-color('ecru-95', 10);
    }
  }

  .store-selected-display {
    display: none;
  }
}

.findinstore-modal {
  .modal-container {
    border-radius: 1rem;
    height: calc(100vh - 3.75rem);
  }

  .modal-close {
    height: 2rem;
    width: 2rem !important;
    right: 0.75rem;
    top: 0.75rem;
  }

  .modal-header {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 17px;
    padding: 1.25rem 1.75rem;
    text-transform: uppercase;
  }

  .modal-content {
    font-size: 15px;
    height: calc(100% - 57px);
    letter-spacing: 0.1px;
    line-height: 160%;
  }

  .mm-button {
    // MARG-534
    width: 100%;
  }
}

#findinstore-modal {
  .box-actions-search-store {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0.75rem 0 2rem;
  }

  .search-form-group {
    align-items: center;
    display: flex;
    flex: 1;
    font-size: 16px;

    [type='text'] {
      background-image: none;
      height: 2.75rem;
      padding-right: 2rem;
    }
  }

  .btn-geolocator {
    background: transparent;
    border: none;
    line-height: 18px;
    padding: 0;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);

    &:first-of-type {
      display: none;
    }
  }

  .mm-icon.pin {
    color: $ecru-50;
  }

  .btn-filter-store {
    width: auto;
  }

  .bopis-error-message {
    font-size: 0.8125rem;
  }

  .stores-list {
    margin-bottom: 4rem;
  }

  .stores {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .store-item {
    border: 2px solid get-color('ecru-95', 10);
    border-radius: 15px;
    color: $ecru-95;
    margin-bottom: 0;
    padding: 1.1rem;

    &.expanded {
      .store-phone,
      .store-extended-info {
        display: block;
      }
    }

    &.checked {
      border-color: $info-10;
    }
  }

  .store-main-info {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    line-height: 18px;
    position: relative;
  }

  .store-name {
    cursor: pointer;
    font-weight: bold;
    width: 180px;

    [type='radio'] {
      align-self: flex-start;
    }
  }

  .store-more-info {
    background: transparent;
    border: none;
    color: $ecru-50;
    padding: 0;
    position: absolute;
    right: 0.25rem;
    top: 0;
  }

  .store-address-short {
    color: $ecru-50;
    font-size: 13px;
    letter-spacing: 0.1px;
    line-height: 160%;
    margin-top: 18px;
  }

  .store-phone {
    display: none;
    font-size: 15px;
    margin-top: 25px;
    order: 2;

    & a {
      display: flex;
      align-items: center;
      gap: 7px;
    }
  }

  .store-hours {
    font-size: 17px;
    line-height: 175%;
    margin-top: 1rem;
    order: 1;
  }

  .store-extended-openinghours {
    display: none;
  }

  .single-store-hour {
    font-size: inherit;
    margin: 0;
  }

  .store-avail-message {
    color: $ecru-50;
    font-size: 13px;
    letter-spacing: 0.1px;
    line-height: 160%;
    margin-top: 1rem;
    padding-left: 1.25rem;
    position: relative;

    &:before {
      border-radius: $border-radius-full;
      content: '';
      display: block;
      height: 0.5rem;
      left: 3px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 0.5rem;
    }

    &.availablenow:before {
      background: $success-50;
    }

    &.availabledays:before {
      background: $warning-50;
    }

    &.outofstock:before {
      background: $danger-50;
    }
  }

  .store-extended-info {
    display: none;
    margin-top: 22px;

    .mm-button {
      width: auto;
    }
  }

  .btn-add-to-cart {
    display: none;
  }

  .btn-confirm-address {
    bottom: 1rem;
    font-size: 16px;
    left: 1rem;
    line-height: 19px;
    position: absolute;
    width: calc(100% - 2rem);
  }
}

#bopis-alert {
  .modal-container {
    height: auto;
    min-height: 50vh;
  }

  .modal-content {
    padding-top: 2rem;

    .mm-button {
      height: 3.5rem;
      margin-top: 1rem;
    }
  }
}

.list-products {
  display: flex;
  flex-direction: column-reverse;
  gap: 2.5rem;

  .title-list {
    line-height: 17px;
    margin-bottom: 0;

    & + .unstyled-list {
      // margin-top: 3rem;
      margin-top: rem(24);
    }

    & + .store-item {
      margin-top: 1.75rem;
    }
  }

  .store-item {
    margin-bottom: 2rem;

    .bopis-btn {
      background: get-color('ecru-95', 10);
      justify-content: space-between;
      padding: 0 0.75rem;
    }

    .bopis-actions-wrapper {
      flex: 1;
      margin-left: 0.5rem;
      text-align: left;

      .option-ship-type.change-store-option {
        .icon--info {
          display: none;
        }
      }
    }

    .circled-plus {
      color: get-color('ecru-95', 30);
      display: none;
    }
  }

  .ship-action {
    font-size: 13px;
    line-height: 16px;
  }
}

#confirmation-bopis-modal {
  .modal-container > div {
    display: flex;
    flex-direction: column;
  }

  .modal-header {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 15px;
    padding: 1.4rem 1.75rem;
  }

  .modal-content {
    padding: 2.25rem 1rem 1rem;
    flex: 1;
  }

  .modal-confirmation-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  p {
    font-size: 18px;
    letter-spacing: normal;
    line-height: 170%;
    padding: 0 1rem;
  }

  .modal-confirmation-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    button {
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 16px;
      text-transform: uppercase;
    }
  }
}

.checkoutpage.review .checkout-review {
  display: flex;
  flex-direction: column;
  padding: 0 0.75rem;

  h3 {
    font-size: 15px;
    line-height: 18px;
  }

  > .row {
    align-self: center;
    padding: 1.25rem 0.75rem;
    width: calc(100% + 1.5rem);
  }
}

.bag-review-container > div,
.accordion.is-enabled .is-active > #order-summary-box-content {
  display: flex;
  flex-direction: column-reverse;
}

.bag-review-store {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  line-height: 19px;
  margin: 1.5rem 0 3rem;

  .circled-plus {
    color: get-color('ecru-95', 30);
    display: none;
  }
}

.bag-product__shipping {
  flex: 1;
  font-size: 14px;
  line-height: 17px;

  + div {
    flex: 0;
  }
}

.available-days-additional-text {
  display: block;
}
