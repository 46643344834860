.mm-color-swatch {
  &.doubled-color {
    .color-1 {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      width: 0.25rem !important;
    }
    .color-2 {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      width: 0.25rem !important;
    }
  }
  &.selected-color {
    box-shadow: 0 0 0 2px $info-50;
  }
}
