@mixin backdrop-blur($blur: 1, $mode: 'default') {
  @include supports-backdrop-filter {
    @if $blur == 1 {
      backdrop-filter: blur(135px);
    } @else if $blur == 2 {
      backdrop-filter: blur(100px);
    } @else if $blur == 3 {
      backdrop-filter: blur(32px);
    }

    @if $mode == 'default' {
      background-color: get-color('ecru-05', 30);
    } @else if $mode == 'inverted' {
      background-color: get-color('ecru-95', 50);
    }
  }

  @include supports-not-backdrop-filter {
    @if $mode == 'default' {
      background-color: get-color('ecru-05', 90);
    } @else if $mode == 'inverted' {
      background-color: get-color('ecru-95', 50);
    }
  }
}
