.mm-menu-logo {
  svg {
    height: 30px;
    width: auto;
  }
}

.mm-menu__primary__start > .mm-button {
  flex-shrink: 0;
  max-width: rem(85);
}
.mm-menu__primary__end .js-button-minicart {
  flex-shrink: 0;
  max-width: rem(85);
}

.mm-menu__primary__end {
  .mm-menu__primary__list li:last-child {
    margin-left: 0;
  }
}
//

[lang='ja-jp'] {
  .mm-menu__primary__start {
    .js-menu-open {
      white-space: nowrap;
    }
  }
}

@media (min-width: get($grid-breakpoints, xs)) and (max-width: 389px) {
  .mm-menu__primary__center {
    width: inherit;
  }

  .mm-menu-logo {
    width: auto;
    svg {
      height: 28px;
      width: inherit;
    }
  }
}
//
