.experience-layouts-MMCarousel {
  .swiper-pagination {
    width: 40%;
    display: flex;
    position: static;
    margin: 2rem auto;
    justify-content: center;
    .swiper-pagination-bullet {
      width: auto;
      max-width: 2rem;
      height: 2px;
      margin: 0 !important;
      border-radius: 0;
      flex-grow: 1;
      &.swiper-pagination-bullet-active {
        background-color: $ecru-95;
      }
    }
  }
  &:has(.image-content-title) {
    .swiper-pagination.swiper-pagination-bullets {
      margin-top: 25px;
    }
}


  .swiper-slide {
    padding: 0 0.25rem;
    width: rem(246);
  }

  .image-video-component {
    border-radius: 0;
  }
  .border-radius-slide {
    .image-video-component {
      border-radius: $border-radius-5;
    }
  }

  .experience-assets-MMbutton {
    .mm-button {
      @include type('global-control-expressive-2');
    }
  }

  .experience-carouselItemContent {
    width: 100%;
  }
}

.container-carousel {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

// IBT-3293
.experience-layouts-MMCarousel {
  .swiper-slide {
    height: auto; // https://stackoverflow.com/a/33644245
    display: flex;
    flex-direction: column;
  }

  .experience-carouselItemContent {
    flex: 1;
    display: flex;
    flex-direction: column;

    > div:first-child {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
  .image-video-component,
  .image-video-component > a {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .mm-videoloop.uninitialized {
    //Workarkound; to be refactored
    @include ratio-2x3();
    height: 0;
  }
}

// IBT-3402
.experience-layouts-MMCarousel [class*='carouselItemContent'] {
  .single-element-content-image,
  .image-video-component,
  > div:nth-child(2) {
    transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:hover {
    .single-element-content-image,
    .image-video-component {
      transform: scale(0.975);
    }

    > div:nth-child(2) {
      transform: translate3d(0, -0.25rem, 0);
    }
    [class*='product-card'] {
      @include media-hover {
        .mm-product-card__hero:hover {
          transform: none;
        }
      }
    }
  }

  .image-video-component {
    border: 1px solid transparent;
  }
}

.experience-layouts-MMCarousel {
  .swiper-container {
    .single-element-content-image {
      .image-container {
        height: 100%;

        .mouseover-container.mm-product-card__hero {
          height: 100%;

          .mouseover-wrapper.mm-product-card__hero__aspect {
            height: 100%;

            .mm-product-card__hero__default.default-image.single-image.mm-video-card {
              height: 100%;
            }
          }
        }
      }
    }
  }
}
