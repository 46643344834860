.mm6-body {
  .recommendations-search-mm {
    display: none;
  }

  .search-recommendations-top-mm {
    display: none;
  }
  .recommendations-search-mm6 {
    display: block !important;
  }

  .search-recommendations-top-mm6 {
    display: block !important;
  }
}
.mm-body {
  .recommendations-search-mm6 {
    display: none;
  }

  .search-recommendations-top-mm6 {
    display: none;
  }
  .recommendations-search-mm {
    display: block !important;
  }

  .search-recommendations-top-mm {
    display: block !important;
  }
}

.mm-menu-search-result-item {
  width: 100%;
  border-radius: 0.75rem;
  padding: 0.25rem;
  cursor: pointer;
  display: block;
}

.mm-menu-search-result-item.selected,
.mm-menu-search-result-item:hover {
  background-color: rgba(18, 18, 18, 0.06);
}

.item.selected .mm-button.-solid {
  @include media-hover {
    background-color: get-color('ecru-95', 10);
  }
}

.mm-menu-search-result-item__container {
  width: 100%;
  display: flex;
}

.mm-menu-search-result-item__thumbnail {
  width: 52px;
  border-radius: 10px;
}

.mm-menu-search-result-item__thumbnail img {
  object-fit: cover;
}

.mm-menu-search-result-item__content {
  display: flex;
  flex-direction: column;
  padding-left: 0.75rem;
}

.mm-menu-search-result-item__content__end {
  display: flex;
  height: 100%;
  align-items: center;
  opacity: 0.24;

  span {
    border: none !important;
    padding: 0 !important;
  }
}

.mm-menu-search-result-item:hover .mm-menu-search-result-item__content__end {
  opacity: 1;
}

.mm-menu-search {
  display: none;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-direction: column;
  &.-show {
    display: flex;
  }
}
.mm-menu-search .mm-form-search-input {
  position: static;
  + .mm-button {
    margin-left: 0.5rem;
    flex-shrink: 0;
  }
}
.mm-menu-search__bar {
  height: 3rem;
  align-items: center;
  flex-shrink: 0;
}
.mm-menu-search__bar,
.mm-menu-search__results {
  position: relative;
  width: 100%;
  padding: 0.25rem;
  display: flex;
}
.mm-menu-search__results {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  flex-direction: column;
  justify-content: space-between;
  min-height: 306px;
  max-height: 369px;
  height: 100vh;
}
.mm-menu-search__results__start {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
}
.mm-menu-search__results__end {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  width: calc(100vw - 2.5rem);
  bottom: 0;
}
.mm-menu-search__results__list {
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 0.875rem;
  transform: translateZ(0);
  overflow: auto;
  position: absolute;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.mm-menu-search__results li,
.mm-menu-search__results ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.mm-menu-search__results li {
  padding: 0.125rem 0;
}
.suggestions-wrapper-container {
  top: calc(100% + 0.25rem);
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 1rem;
  z-index: 1;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
  max-height: 76vh;
  padding-bottom: 120px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  display: grid;
  grid-template-areas:
    'suggestions'
    'recommendations';
  grid-auto-columns: minmax(0, 1fr);
  grid-template-rows: min-content;

  .suggestions-wrapper {
    position: relative;
    left: 0;
    right: 0;
    border-radius: 1rem;
    min-height: 241px;
    max-height: 300px;
    z-index: 1;
    width: 100%;
    height: 100%;

    .mm-menu-search__results {
      max-height: 300px;
      height: 100%;
    }
  }

  .left-container {
    grid-area: suggestions;
    padding: 13px;
    height: fit-content;
    .search-recommendations-top {
      margin-bottom: 16px;

      .unstyled-list {
        display: flex;

        .search-suggestion-list-item {
          color: $ecru-95;
          font-family: 'Margiela Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          width: 100%;
        }

        .mm-search-suggestion-list-item {
          width: fit-content;
          margin-right: 16px;
          background-color: #00000014;
          border-radius: 10px;

          a {
            padding: 8px;
            display: block;
            color: $ecru-95;
            font-family: 'Margiela Sans';
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }

      .unstyled-list-einstein {
        flex-direction: column;

        .search-suggestion-list-item {
          padding: 16px;

          &:hover {
            background: #00000014;
            border-radius: 12px;
          }
        }
      }
    }

    .recent-searches {
      margin-top: 15px;
    }

    .title-section-search-suggestions {
      color: $ecru-95;
      font-family: 'Margiela Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 16px;
    }
  }
  .page-search-result-container {
    grid-area: recommendations;
    height: fit-content;
    padding: 13px;

    .experience-layouts-MMCarousel .swiper-slide {
      width: 12rem;
    }
  }

  .search-no-result-decorator-container {
    .experience-component {
      background: transparent;
    }

    .hide-search-page {
      display: none !important;
    }

    .search-page {
      display: block !important;
    }

    .container-carousel {
      max-width: none;

      .add-to-wish-list {
        display: none !important;
      }

      .mm-product-card__title {
        color: $ecru-95;
        font-family: 'Margiela Sans';
        text-align: center;
        font-feature-settings: 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 457;
        line-height: 140%; /* 22.4px */
        letter-spacing: -0.15px;
      }

      .price {
        .value {
          color: $ecru-95;
          font-family: 'Margiela Sans';
          text-align: center;
          font-size: 13px;
          font-style: normal;
          font-weight: 556;
          line-height: normal;
        }
      }

      .single-element-content-detail-badge {
        display: none;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    h2,
    .search-recommendations-products-title {
      color: $ecru-95;
      font-family: 'Margiela Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 16px;
      padding: 0 0.25rem;
    }

    .search-recommendations-products {
      display: flex;
      gap: 8px;

      .product {
        width: calc(25% - 8px);

        .add-to-wish-list {
          display: none !important;
        }
      }

      .product:last-child {
        margin-right: 0;
      }
    }
  }
}

.mm-menu-search__results__end {
  .mm-button {
    position: static;
  }
}
