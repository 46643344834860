.chat-bubble {
  max-width: 100%;
  position: relative;

  .bot-profilepic {
    position: absolute;
    top: 10px;
    left: 0;
    display: flex;
    height: 32px;
    width: 32px;
    background-color: black;
    border-radius: 100px;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;

    .mm-icon {
      width: 1.5rem;
      height: 1.5rem;

      svg {
        color: #fff;
      }
    }
  }

  .message {
    border-radius: 15px;
    padding: 16px;
    font-size: 14px;
    margin-top: 10px;
    width: fit-content;
    display: flex;
    flex-wrap: wrap;

    .text {
      margin-bottom: 0;
    }

    .subtext {
      margin-top: 10px;
      margin-bottom: 0;
    }

    .support-links {
      margin: 10px auto 0;
      text-transform: uppercase;

      .mm-button {
        font-weight: 700;
        min-width: 210px;
        font-size: 14px;
      }
    }
  }

  &.bot {
    align-self: flex-start;
    display: flex;
    margin-bottom: 20px;

    .message {
      padding: 24px 16px;
      margin-left: 56px;
      border-top-left-radius: 0;
      background-color: #f7f7f2;

      .text {
        width: 100%;
      }

      &.loading {
        background-color: transparent;
        margin-bottom: 2rem;

        .writing-loader {
          position: relative;
          margin-left: 2rem;

          .dot {
            position: absolute;
            height: 0.6rem;
            width: 0.6rem;
            border-radius: 50%;
            background-color: black;
            animation: bounce 1.3s linear infinite;

            &:nth-child(1) {
              left: -2rem;
            }

            &:nth-child(2) {
              animation-delay: -1s;
            }

            &:nth-child(3) {
              right: -2.6rem;
              animation-delay: -0.8s;
            }
          }
        }
      }

      &.login-form {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);

        .login-header {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .title {
            font-family: 'Margiela Sans';
            font-weight: 700;
            font-size: 12px;
            margin-bottom: 30px;
          }

          .login-close,
          .login-back {
            margin: 0;
            padding: 0;
            display: flex;
            border: 0;
            background: transparent;
            color: #1a1a1a;
          }

          .login-close {
            width: 22px;
            height: 24px;

            .mm-icon.close {
              width: 22px;
              height: 24px;
            }
          }
        }

        .login-header-subtitle {
          font-family: 'Margiela Sans';
          font-weight: 400;
          font-size: 12px;
          margin-bottom: 1rem;
        }

        form.login,
        form.reset-password-form {
          width: 100%;

          .mm-form-input input,
          .mm-form-input label {
            background-color: #f7f7f2;
          }

          .mm-form-input {
            input:valid {
              border-color: #1f69ff;
              outline: 0;
              box-shadow: 0 0 0 4px rgba(31, 105, 255, 0.14);
            }

            input:valid ~ label {
              color: #1f69ff;
            }
          }

          .recaptcha-subtext {
            margin-top: 10px;
            margin-bottom: 0;
            font-size: 10px;
            font-family: 'Margiela Serif';

            a {
              color: #1f69ff;
            }
          }
        }

        form.login {
          .forgot-password {
            background-color: transparent;
            text-align: right;
            font-size: 11px;
            font-family: 'Margiela Sans';
            font-weight: 700;
            color: rgba(18, 18, 18, 0.3);
            margin: 0;
            border: 0;
          }
        }
      }
    }

    &.feedback {
      .message {
        .feedback-actions {
          display: flex;
          width: 100%;
          justify-content: space-evenly;
          max-width: 140px;
          margin-top: 15px;

          .feedback-emote {
            background-color: transparent;
            border-radius: 4px;
            padding: 5px;
            border: 1px solid rgba(18, 18, 18, 0.3);
            width: 30px;
            height: 30px;

            &:hover {
              background-color: rgba(18, 18, 18, 0.06);
              border: 1px solid rgba(18, 18, 18, 0.06);
            }

            &.selected {
              border: 1px solid #1f69ff;

              &:disabled {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    &.products,
    &.chips {
      .message {
        background-color: transparent;
      }
    }

    &.products {
      max-width: 100%;

      .message {
        padding-top: 0;
        max-width: 100%;

        .swiper-wrapper {
          margin-bottom: 60px;

          .product-chatbot-tile {
            width: 140px;

            .product {
              &-image {
                border-radius: 27px;
                overflow: hidden;
                margin-bottom: 15px;

                img {
                  max-width: 100%;
                }
              }

              &-name {
                font-family: 'Margiela Serif';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }

              &-price {
                font-family: 'Margiela Sans';
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                margin-bottom: 8px;
              }

              &-selected-color {
                font-family: 'Margiela Serif';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                margin-bottom: 8px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                min-height: 18px;
              }

              &-colors {
                display: flex;
                align-items: center;
                justify-content: center;

                .color-swatch {
                  width: 1rem;
                  height: 1rem;
                  border-radius: 4px;
                  margin-right: 4px;

                  &.selected {
                    border: 1px solid #1f69ff;
                  }
                }

                .plus {
                  font-family: 'Margiela Sans';
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                }
              }
            }

            .tile-link {
              max-width: 100%;

              .product-image {
                .image-0 {
                  display: block;
                }

                .image-1 {
                  display: none;
                }

                .img-fluid {
                  max-width: 100%;
                }
              }

              &:hover {
                .product-image {
                  .image-0 {
                    display: none;
                  }

                  .image-1 {
                    display: block;
                  }
                }
              }
            }

            .like-product-button {
              background-color: transparent;
              border: 1px solid rgba(26, 26, 26, 0.3);
              color: #1a1a1a;
              border-radius: 25px;
              font-family: 'Margiela Serif';
              font-size: 10px;
              max-height: 30px;

              &:hover {
                border: 2px solid rgba(26, 26, 26, 0.3);
              }

              &.selected-product {
                color: #fff;
                background-color: #1a1a1a;
              }
            }
          }
        }

        .swiper-arrow {
          bottom: 0;
          top: unset;
          width: 44px;
          height: 44px;
          text-align: center;

          &.swiper-button-next,
          &.swiper-button-prev {
            &:after {
              font-size: 14px;
              color: #1a1a1a;
              font-weight: 700;
              margin-left: 2px;
              background-color: #f7f7f2;
              border-radius: 25px;
              height: 24px;
              width: 24px;
              padding: 5px;
            }
          }

          &.swiper-button-next {
            right: 50px;
          }

          &.swiper-button-prev {
            left: 50px;
          }
        }

        .swiper-pagination-progressbar {
          top: unset;
          bottom: 20px;
          width: 20%;
          left: 50%;
          transform: translate(-50%, 0);
          height: 2px;

          .swiper-pagination-progressbar-fill {
            background-color: #1a1a1a;
            height: 2px;
          }
        }
      }
    }

    &.chips {
      margin-bottom: 10px;
      .message {
        margin-top: 0;
        padding-bottom: 0;
        padding-top: 0;

        .chip {
          border: 1px solid rgba(26, 26, 26, 0.3);
          border-radius: 15px;
          padding: 9px 12px;
          font-size: 12px;
          font-family: 'Margiela Serif';
          width: fit-content;
          margin-right: 10px;
          margin-bottom: 10px;
          background-color: transparent;
          text-align: left;

          &:hover {
            border: 2px solid rgba(26, 26, 26, 0.3);
          }
        }
      }
    }

    &.extra-message {
      .section-icon-social {
        a[class*='icon--'] {
          width: 2rem;
          height: 2rem;
          display: inline-block;
          margin-right: 0.5rem;
        }
      }
    }
  }

  &.sender {
    align-self: flex-end;
    .message {
      border-bottom-right-radius: 0;
      background-color: #1f69ff;
      color: #fff;
    }

    .message-delivered {
      margin-top: 4px;
      margin-bottom: 20px;
      font-size: 10px;
      line-height: 18px;
      display: flex;
      justify-content: flex-end;

      svg {
        color: #1f69ff;
      }
    }
  }
}

// animation
@keyframes bounce {
  0%,
  66%,
  100% {
    transform: initial;
  }

  33% {
    transform: translatey(-1rem);
  }
}
