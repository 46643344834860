$base_margin_mobile: 0.5rem;

.pd-whiteSpace {
  width: 100%;
  position: relative;
  z-index: 1;
  background-color: var(--mm-body-bg);
}
.pd-whiteSpace-1x {
  height: $base_margin_mobile;
}
.pd-whiteSpace-2x {
  height: $base_margin_mobile * 2;
}
.pd-whiteSpace-3x {
  height: $base_margin_mobile * 3;
}
.pd-whiteSpace-4x {
  height: $base_margin_mobile * 4;
}
.pd-whiteSpace-5x {
  height: $base_margin_mobile * 5;
}
.pd-whiteSpace-6x {
  height: $base_margin_mobile * 6;
}
.pd-whiteSpace-7x {
  height: $base_margin_mobile * 7;
}
.pd-whiteSpace-8x {
  height: $base_margin_mobile * 8;
}
