.chat-bubble {
  max-width: 65%;

  &.bot {
    &.products {
      .message {
        .swiper-wrapper {
          margin-bottom: 10px;
        }

        .swiper-arrow,
        .swiper-pagination-progressbar {
          display: none;
        }
      }
    }

    &.feedback {
      max-width: 40%;
    }
  }
}