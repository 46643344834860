.mm-menu__primary {
  height: $header-height-mdmin; // IBT-3745
}

.show-mobile {
  display: none !important;
}

.mm-menu__primary__end .mm-menu__primary__list .js-button-minicar {
  padding: 0 1rem;
}

.js-inner-menu {
  border-radius: 16px;
}

.primary-open {
  border-radius: 0.75rem 0.75rem 0 0;
  background: #dadad7;
  width: 100%;

  .mm-menu__primary__list {
    align-self: start;
  }

  .brands-menu {
    align-self: start;
    .mm-menu__secondary__tabs {
      flex-direction: row;

      li {
        width: fit-content;
      }
    }
  }

  .categoriesMainLinks {
    justify-content: left;
    flex-direction: column !important;
  }
}

.submenu {
  background: linear-gradient(180deg, #cfcfcb 0%, #e3e3df 100%);
}

.search-is-open {
  .close-label-mobile {
    display: none;
    background: rgba(18, 18, 18, 0.06);
  }

  .js-search-close {
    position: absolute;
    background: transparent;
  }

  .mm-form-search-input input:not(:placeholder-shown) {
    background-image: none;
  }

  .close-icon {
    display: none;
  }

  .mm-form-search-input {
    input {
      padding: 0 3rem 0 3.5rem !important;
      background-position: left 0.75rem center !important;
      background-image: none;

      &.accessibility-search-input {
        background-image: none;
        padding-left: 2.2rem;

        &:focus,
        &:not(:placeholder-shown) {
          padding-left: 2.2rem;
          clip-path: polygon(-5px -5px, 14px -5px, 14px 5px, 55px 5px, 55px -5px, 101% -5px, 101% 44px, 0 44px);
        }
      }
    }
  }

  .mm-menu-search .mm-form-search-input + .mm-button {
    background: transparent;
    width: 2.5rem;

    &:after {
      content: '';
      display: none;
    }
  }
}

.mm-menu__primary__start {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  > .mm-button {
    display: none;
  }

  > .mm-menu__primary__list {
    display: inline;
    background-color: none;
    // position: relative;
    // top: 0;
    height: fit-content;
    backdrop-filter: none;
    background: none;

    > ul {
      display: flex;
      flex-direction: row;
      width: auto;
      justify-content: left;

      .mm-menu__primary__item > a {
        padding-left: 16px;
        padding-right: 16px;
        margin-left: 2px;
        margin-right: 2px;
        height: 2.5rem;
      }

      .mm-menu-item {
        .js-primary-li {
          border-radius: 0 0 16px 16px;
        }

        a {
          padding: 0 11px;
          justify-content: flex-start;
          min-height: 28px;
          display: inline-flex;
          width: 100%;
          align-items: center;
        }
      }

      .mm-menu__primary__item {
        background: none;
      }
    }

    li + li {
      margin-left: 0;
    }
  }
}

.mm-menu__primary__end {
  .mm-menu__primary__list {
    justify-content: flex-end;
    margin: 0 20px;
  }

  li {
    width: auto;
  }
}

.mm-menu__primary__end .mm-menu__primary__list .js-button-minicart {
  padding: 0 1rem;
}

.mm-menu__primary__item {
  position: relative;
  width: inherit;
  .-navigation-a11y {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, 33%);
  }

  > a {
    &:hover {
      ~ .mm-menu__secondary {
        .mm-menu__secondary__start,
        .mm-menu__secondary__end {
          display: flex;
        }
      }
    }
    ~ .mm-menu__secondary {
      .mm-menu__secondary__start,
      .mm-menu__secondary__end {
        display: none;
      }
    }
  }

  .mm-menu__secondary {
    &:hover {
      .mm-menu__secondary__start,
      .mm-menu__secondary__end {
        display: flex;
      }
    }
    &.is-open {
      .mm-menu__secondary__start,
      .mm-menu__secondary__end {
        display: flex;
      }
    }
  }
}
.menu-open {
  max-width: calc(100vw - 2rem) !important;
  .mm-menu__secondary__end,
  .mm-menu__secondary__start {
    display: flex !important;
  }

  .mm-menu__secondary__start {
    width: fit-content;
  }

  .js-primary-li {
    background: #dadad7 !important;
    border-radius: 0 0 16px 16px;
  }

  .brands-menu {
    background: #dadad7 !important;
    border-radius: 16px 16px 0 0 !important;
  }
}

.mm-menu__secondary__tabs {
  display: flex;
  flex-direction: column;
}

.header-notification-slot-container {
  .swiper {
    width: 100%;
  }
}
.mm-menu__secondary {
  display: flex;
  position: fixed;
  transform: none;
  // visibility: hidden;
  pointer-events: none;
  padding: 0.25rem 0.25rem 0.25rem;
  z-index: 1;
  max-height: calc(var(--initial-vh, 1vh) * 100 - 2rem);
  width: calc(100vw - 3rem);
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: $border-radius-6;
  backdrop-filter: none;
  // IBT-3588
  // background-color: none;
  // background: none;
  // background: rgba(26, 26, 26, 0.08);

  &.is-open {
    visibility: visible;
    pointer-events: all;
    min-height: rem(250);

    .submenu-content {
      padding: 0.25rem 0.25rem 0.25rem;
    }
  }

  .submenu-content {
    border-radius: $border-radius-6;
    width: 100%;
    display: flex;
  }
}

.mm-menu-mobile__controls button:focus,
.mm-menu-mobile__controls button:hover {
  color: inherit;
}

.mm-menu-mobile__secondary-end,
.mm-menu-mobile__secondary-end {
  display: none;
}

.mm-menu-mobile__secondary {
  display: none;
}

.categoriesMainLinks {
  margin-left: 4px;
  .categories-menu {
    margin-top: 4px;

    .-active {
      .menu-title {
        background-color: rgba(18, 18, 18, 0.06) !important;
        border-radius: 0.75rem;
      }
    }
  }
}

.menu-open {
  .categoriesMainLinks {
    .categories-menu {
      margin-top: 0;
    }
  }
}

// .categoriesMainLinks {
//   .categories-menu {
//     opacity: 1;
//     transform: translateY(-100%);
//     transition: 1s ease-in-out;
//   }
// }

// .categoriesMainLinks.animate {
//   .categories-menu {
//     opacity: 1;
//     transform: translateY(0%);
//     transition: 1s ease-in-out;
//   }
// }

.mm-menu__primary__end,
.mm-menu__primary__start {
  flex-basis: 50%;
}

.menu-pd-content-container {
  .carousel-one-slide,
  .carousel-vertical {
    .swiper-slide {
      height: 245px;
    }
  }

  .carousel-one-slide {
    width: 182px;
    padding: 0;
  }

  .image-video-component {
    height: 100%;

    picture {
      height: 100%;
    }
  }
}
