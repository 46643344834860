@import 'style/account/**/_*.default.scss';
@import 'style/address/**/_*.default.scss';

@import 'style/components/accordion/_*.default.scss';
@import 'style/components/color-swatch/_*.default.scss';
@import 'style/components/cookies-popup/_*.default.scss';
@import 'style/components/datepicker/_*.default.scss';
@import 'style/components/dynamic-delivery-date/_*.default.scss';
@import 'style/components/findinstore/_*.default.scss';
@import 'style/components/country-selector/_*.default.scss';
@import 'style/components/geoip-modal/_*.default.scss';
@import 'style/components/image-video/_*.default.scss';
@import 'style/components/image-video-cta/_*.default.scss';
@import 'style/components/logged-menu/_*.default.scss';
@import 'style/components/login-modal/_*.default.scss';
@import 'style/components/message-notification/_*.default.scss';
@import 'style/components/minicart/_*.default.scss';
@import 'style/components/modal/_*.default.scss';
@import 'style/components/newsletter-modal/_*.default.scss';
@import 'style/components/newsletter-strip/_*.default.scss';
@import 'style/components/order-return/_*.default.scss';
@import 'style/components/page-designer/_*.default.scss';
@import 'style/components/select/_*.default.scss';
@import 'style/components/switch-button/_*.default.scss';
@import 'style/components/table/_*.default.scss';
@import 'style/components/tooltip/_*.default.scss';

@import 'style/layout/**/_*.default.scss';


@import 'style/pages/product/_*.default.scss';
@import 'style/pages/default/_*.default.scss';

@import 'style/experience/**/_*.default.scss';
@import 'style/wishlist/**/_*.default.scss';
@import '~app_project/components/accordionmini/_accordion-mini.default.scss';
@import 'style/chatbot/index';
// @media MIN

@include breakpoint(sm, min) {
  @import 'style/account/**/_*.sm-min.scss';
  @import 'style/address/**/_*.sm-min.scss';
  @import 'style/components/modal/_*.sm-min.scss';
  @import 'style/components/order-return/_*.sm-min.scss';
  @import 'style/components/page-designer/_*.sm-min.scss';

  @import 'style/layout/**/_*.sm-min.scss';

  @import 'style/pages/default/_*.sm-min.scss';
  
  @import 'style/experience/**/_*.sm-min.scss';
}

@include breakpoint(md, min) {
  @import 'style/account/**/_*.md-min.scss';
  @import 'style/address/**/_*.md-min.scss';
  @import 'style/components/cookies-popup/_*.md-min.scss';
  @import 'style/components/findinstore/_*.md-min.scss';
  @import 'style/components/country-selector/_*.md-min.scss';
  @import 'style/components/login-modal/_*.md-min.scss';
  @import 'style/components/message-notification/_*.md-min.scss';
  @import 'style/components/modal/_*.md-min.scss';
  @import 'style/components/newsletter-modal/_*.md-min.scss';
  @import 'style/components/newsletter-strip/_*.md-min.scss';
  @import 'style/components/order-return/_*.md-min.scss';
  @import 'style/components/page-designer/_*.md-min.scss';
  @import 'style/components/tooltip/_*.md-min.scss';

  @import 'style/layout/**/_*.md-min.scss';

  @import 'style/pages/product/_*.md-min.scss';
  @import 'style/pages/default/_*.md-min.scss';

  @import 'style/experience/**/_*.md-min.scss';
  @import 'style/chatbot/components/*.md-min.scss';
}

@include breakpoint(lg, min) {
  @import 'style/account/**/_*.lg-min.scss';
  @import 'style/address/**/_*.lg-min.scss';
  @import 'style/components/order-return/_*.lg-min.scss';
  @import 'style/components/page-designer/_*.lg-min.scss';

  @import 'style/layout/**/_*.lg-min.scss';

  @import 'style/pages/product/_*.lg-min.scss';
  @import 'style/pages/default/_*.lg-min.scss';

  @import 'style/experience/**/_*.lg-min.scss';
}

@include breakpoint(xl, min) {
  @import 'style/account/**/_*.xl-min.scss';
  @import 'style/address/**/_*.xl-min.scss';
  @import 'style/components/order-return/_*.xl-min.scss';
  @import 'style/components/page-designer/_*.xl-min.scss';

  @import 'style/layout/**/_*.xl-min.scss';

  @import 'style/pages/default/_*.xl-min.scss';

  @import 'style/experience/**/_*.xl-min.scss';
}

@include breakpoint(xxl, min) {
  @import 'style/account/**/_*.xxl-min.scss';
  @import 'style/address/**/_*.xxl-min.scss';
  @import 'style/components/order-return/_*.xxl-min.scss';
  @import 'style/components/page-designer/_*.xxl-min.scss';

  @import 'style/layout/**/_*.xxl-min.scss';

  @import 'style/pages/default/_*.xxl-min.scss';

  @import 'style/experience/**/_*.xxl-min.scss';
}

// @media BETWEEN

@media (min-width: get($grid-breakpoints, xs)) and (max-width: (get($grid-breakpoints, sm) - 1)) {

  @import 'style/components/page-designer/_*.xs.scss';
  @import 'style/layout/**/_*.xs.scss';
  @import 'style/pages/default/_*.xs.scss';
  @import 'style/experience/**/_*.xs.scss';
}

@media (min-width: get($grid-breakpoints, sm)) and (max-width: (get($grid-breakpoints, md) - 1)) {

  @import 'style/components/page-designer/_*.sm.scss';
  @import 'style/layout/**/_*.sm.scss';
  @import 'style/pages/default/_*.sm.scss';
  @import 'style/experience/**/_*.sm.scss';
}

@media (min-width: get($grid-breakpoints, md)) and (max-width: (get($grid-breakpoints, lg) - 1)) {

  @import 'style/components/page-designer/_*.md.scss';
  @import 'style/layout/**/_*.md.scss';
  @import 'style/pages/default/_*.md.scss';
  @import 'style/experience/**/_*.md.scss';
}

@media (min-width: get($grid-breakpoints, lg)) and (max-width: (get($grid-breakpoints, xl) - 1)) {

  @import 'style/components/page-designer/_*.lg.scss';
  @import 'style/layout/**/_*.lg.scss';
  @import 'style/pages/default/_*.lg.scss';
  @import 'style/experience/**/_*.lg.scss';
}

// @media MAX

@include breakpoint(lg) {
  @import 'style/components/page-designer/_*.lg-max.scss';
  @import 'style/layout/**/_*.lg-max.scss';
  @import 'style/pages/default/_*.lg-max.scss';
  @import 'style/experience/**/_*.lg-max.scss';
}

@include breakpoint(md) {
  @import 'style/components/image-video-cta/_*.md-max.scss';
  @import 'style/components/page-designer/_*.md-max.scss';
  @import 'style/layout/**/_*.md-max.scss';
  @import 'style/pages/product/_*.md-max.scss';
  @import 'style/pages/default/_*.md-max.scss';
  @import 'style/experience/**/_*.md-max.scss';
}

@include breakpoint(sm) {
  @import 'style/components/image-video/_*.sm-max.scss';
  @import 'style/components/login-modal/_*.sm-max.scss';
  @import 'style/components/page-designer/_*.sm-max.scss';
  @import 'style/layout/**/_*.sm-max.scss';
  @import 'style/pages/default/_*.sm-max.scss';
  @import 'style/experience/**/_*.sm-max.scss';
  @import 'style/chatbot/components/*.sm-max.scss';
}

@include breakpoint(xs) {
  @import 'style/components/page-designer/_*.xs-max.scss';
  @import 'style/layout/**/_*.xs-max.scss';
  @import 'style/pages/default/_*.xs-max.scss';
  @import 'style/experience/**/_*.xs-max.scss';
}
