.fullscreen-modal {
  .modal-overlay {
    align-items: center;
    justify-content: center;
  }
  .modal-container {
    border-radius: $border-radius-12;
    height: calc(100vh - 34px);
    top: 17px;
    width: 24.625rem;
    .modal-footer {
      width: 22.625rem;
    }
  }
}
