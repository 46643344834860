.section-newsletter-footer,
.newsletter-journey-component {
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &.thank-you {
    height: 12rem;
  }

  p.section-newsletter-title {
    font-size: 1.5rem;
  }

  .newsletter-subtext {
    margin-bottom: 4rem;
  }

  .form-newsletter .mm-form-input:not(.-invalid) input {
    background-image: none;
  }

  .newsletter-journey-text-content {
    display: flex;
    flex-direction: column;
  }

  .section-newsletter-text {
    margin-top: 1rem;
    text-align: left;
    @include type(global-small-1);
    color: get-color('ecru-95', 50);
  }

  .newsletter-heading ~ .newsletter-section.expand-target.expand-show {
    display: none;
  }

  .expand-target {
    overflow: hidden;
    margin: 0;
    height: 0;
    &.expand-show {
      height: auto;
      transition: all 0.7s cubic-bezier(0.16, 1, 0.3, 1);

      &.submit-newsletter-btn {
        position: absolute;
        height: 2rem;
        width: 3.5rem;
        top: 0.5rem;
        right: 0.5rem;
        z-index: 2;
        background-image: $form-input-check;
        background-repeat: no-repeat;
        background-position: right 0 center;
        background-size: 1.125rem 1.125rem;

        .submit-newsletter-btn-text {
          position: absolute;
          left: 0.5rem;
        }
      }
    }

    &:not(.expand-show) {
      background-color: transparent;
    }
  }

  .thank-you-title {
    font-size: 1.5rem;
  }

  .thank-you-text {
    margin-top: 1rem;
    padding: 1rem;
  }

  .thank-you-check {
    background-image: $form-input-check;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2rem 2rem;
    height: 2rem;
    margin-bottom: 2rem;
  }
}

.newsletter-close-button {
  display: none;
}

.newsletter-journey-component.row {
  max-width: 22.5rem;
  margin: 0 auto;

  .expand-target {
    margin-left: 1rem;
  }
}

.newsletter-journey-plp {
  .two-columns-wrapper {
    height: auto;

    .two-columns__text-region {
      padding-bottom: 0;
    }

    .newsletter-close-button {
      display: flex;
      position: absolute;
      width: 2rem;
      height: 2rem;
      right: 0.5rem;
      top: 0.5rem;
      background-color: transparent;
      z-index: 2;
    }
  }

  .newsletter-journey-component {
    .newsletter-heading {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    .newsletter-subtext {
      margin-bottom: 1rem;
    }

    .newsletter-close-button {
      display: none;
    }
  }
}

#newsletter-modal .modal-container {
  height: auto;

  .mm-form-input:not(.-invalid) input {
    background-image: none;
  }

  .submit-newsletter-btn {
    position: absolute;
    height: 2rem;
    width: 3.5rem;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 2;
    background-image: $form-input-check;
    background-repeat: no-repeat;
    background-position: right 0 center;
    background-size: 1.125rem 1.125rem;

    .submit-newsletter-btn-text {
      position: absolute;
      left: 0.5rem;
    }
  }

  #newletter-options {
    margin-left: 1.5rem;
  }
}

.mm-form-radio-wrapper {
  text-align: left;
  .mm-form-message {
    text-align: center;
    margin-top: 1rem;
    &.-invalid {
      display: block;
    }
  }
}
