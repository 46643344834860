.cards-carousel {
  .product {
    .default-image {
      width: rem(344);
    }
  }

  [class*='product-card'] {
    @include media-hover {
      .mm-product-card__hero:hover {
        transform: none;
      }
    }
  }
}
