.mm-menu-feature {
  display: block;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
@media (hover: hover) {
  .mm-menu-feature:hover {
    color: inherit;
  }
}
.mm-menu-feature__title {
  display: flex;
  align-items: center;
  height: 2rem;
  margin-bottom: 0.125rem;
  padding: 0 2rem;
  font-weight: 700;
  @include type(global-control-productive-3);
}

.mm-menu-feature__image {
  position: relative;
  border-radius: 0.75rem;
  transform: translateZ(0);
  overflow: hidden;
  width: 8rem;
  height: 12rem;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 56%;
  }
}

.mm-menu-feature__image img {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.mm-menu-feature.-compact {
  position: relative;
  margin-top: 2rem;
}
.mm-menu-feature.-compact .mm-menu-feature__title {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  margin: 0;
  color: #fff;
  opacity: 1;
  z-index: 1;
}
.mm-menu-feature.-compact .mm-menu-feature__image {
  background-color: #000;
}
.mm-menu-feature.-compact .mm-menu-feature__image img {
  opacity: 0.75;
}
