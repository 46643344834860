.experience-component.two-columns-component {
  overflow: visible !important;
  padding: 3rem 0.5rem 4rem;
}
.two-columns-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  border-radius: $border-radius-12;

  &.-white-background {
    background: get-color('white');
  }

  .media-position-right & {
    flex-direction: column-reverse;
  }

  filter: drop-shadow(0px 16px 32px rgba(0, 0, 0, 0.06));

  // IBT-3419
  box-shadow: 0 32px 96px rgba(0, 0, 0, 0.1); // https://preview.maisonmargiela.digital/ .looks-book
}

.two-columns__text-region,
.two-columns__media-region {
  width: 100%;
}

.two-columns__text-region {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.two-columns-component {
  .two-columns-wrapper {
    overflow: hidden;
    .image-video-component {
      border-radius: 0;
    }
  }
}

.two-columns--with-carousel {
  --autoplay-duration: 4000ms;

  .swiper-slide {
    width: 100%;
    display: block;
  }

  .swiper-pagination {
    box-shadow: 0px 8px 64px rgb(0 0 0 / 96%);
    width: calc(100% - 4rem) !important;
    bottom: 2rem !important;
    left: 50% !important;
    transform: translateX(-50%) !important;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .swiper-pagination-bullet {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background-color: rgba(247, 247, 242, 0.25);
    border-radius: 0;
    opacity: 1;
    margin: 0 !important;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &.swiper-pagination-bullet-active {
      position: relative;
      &:before {
        background-color: #f7f7f2;
        border-radius: 1.5px;
        animation: var(--autoplay-duration) linear progress forwards;
      }
    }
  }
}

@-webkit-keyframes progress {
  0% {
    width: 3px;
  }
  to {
    width: 100%;
  }
}
@keyframes progress {
  0% {
    width: 3px;
  }
  to {
    width: 100%;
  }
}

.packaging-journey,
.newsletter-journey-plp {
  grid-column: 1 / -1;

  .experience-component.two-columns-component {
    padding: 0;
  }
}
