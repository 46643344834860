.checkoutpage {
  &.payment {
    .jp-checkout {
      .cvv-expiration-fields .adyen-checkout-input__inline-validation {
        right: rem(16);
      }
      .adyen-checkout__card__exp-cvc {
        .adyen-checkout__field--50 {
          &.adyen-checkout__field--expiryDate {
            width: 33%;
            .adyen-checkout-input__inline-validation {
              right: rem(10);
            }
          }
        }
      }
    }
    .checkout-payment {
      // TODO remove unnecessary properties
      font-size: 14px;
      line-height: 17px;
      h2 {
        font-size: 13px;
      }
    }

    .billingAddressForm-checkbox {
      margin-left: 3px;
    }

    .accordion-content {
      .col-sm-6 {
        width: 100%;
      }

      .col-12 + .col-12 {
        margin-top: 1rem;
      }
    }

    .payment-method {
      &:not(:first-child) {
        margin-top: 35px;
      }

      > input {
        left: calc(1px + var(--mm-gutter-x) * 0.5);
      }

      &__info {
        font-size: 12px;
      }
    }

    .container-radio-payment {
      .radio__icon {
        .single-payment-box {
          p {
            margin-left: 30px;
          }
        }
      }
    }

    .adyen-checkout {
      /*
      &__checkbox__input {
        width: $form-check-size-md;
        height: $form-check-size-md;
        border-radius: $form-check-size-md-radius;

        &:checked {
          background-image: $form-check-icon-md;
        }
      }
      */

      &__checkbox__label {
        font-size: 14px;
      }
    }

    .cvv-expiration-fields {
      margin-top: 15px;
      .adyen-checkout-input__inline-validation {
        right: 2rem;
      }
    }
  }
}
