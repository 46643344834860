.teaser-sidetoside-content {
  display: flex;
  flex-direction: row;

  .teaser-sidetoside--item {
    + .teaser-sidetoside--item {
      margin-top: 0;
    }
  }

  .two-columns-wrapper {
    flex-direction: column;
  }

  .media-position-right {
    .two-columns-wrapper {
      flex-direction: column-reverse;
    }
  }
}
