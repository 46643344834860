.col-product-info-desktop {
  display: block;
}

.col-product-info-mobile {
  display: none;
}

.stl-product-wrapper {
  .col-product-info {
    height: 100vh;
    padding-top: 12%;
  }

  .product-carousel-main {
    .product-slide {
      margin-bottom: 128px;
    }
  }
}

.recommendations-carousel {
  .product-carousel-title {
    text-align: center;
    text-transform: uppercase;
    margin: 192px 0 58px 0;
    font-size: 11px;
    line-height: 17.6px;
    font-weight: 700;
    letter-spacing: 1px;
  }
}

.product-carousel {
  width: 85% !important;
}

.col-product-info {
  margin-top: 0;
  margin-bottom: 0;
}

.image-carousel-col {
  position: relative;
  height: 100%;
  .swiper-wrapper {
    flex-direction: column;
    height: calc(100vh - (var(--header-height)));
    .product-slide {
      // height: auto !important;
      // align-items: flex-start;
      //min-height: calc(100vh - (var(--header-height) + 1rem)) !important;
      .swiper-zoom-container {
        height: 100%;
        overflow: hidden;
        padding-bottom: 0;
        align-content: center;
      }
      // &:not(:last-of-type) {
      //   margin-bottom: rem(128);
      // }
    }
  }
  .product-carousel-main .swiper-slide {
    img {
      height: 93%;
      // aspect-ratio: 3/5;
      object-fit: contain;
    }
  }
}

.sticky-col {
  position: sticky;
  top: var(--header-height);
}
.thumbs-carousel-col {
  height: 100vh;
  max-height: calc(100vh - (var(--header-height)));
}
.wrapper-pdp {
  padding-top: 1rem;

  .mm-videoloop {
    aspect-ratio: 5 / 7;
    width: auto !important;
    left: 50%;
    transform: translate(-50%);
  }
}
.col-images {
  max-height: calc(100vh - (var(--header-height) + 1.5rem));
}

.col-details {
  height: 100%;
  padding: 0;
}

.details-column-content {
  //padding-top: 3.25rem;
  padding-right: 2vw;
  padding-left: 2vw;
  top: var(--header-height);
  //  top: var(--header-height);

  .product-name {
    margin: 0 auto;
    max-width: 66%;
  }
}

.details-column-content-inner {
  max-width: rem(304);
  margin: 0 auto;
}

// .product-description-content {
//   @include type(mm-body-1);
//   margin-top: 3.5rem;
//   --max-lines: 3;
//   --lh: 1.575rem; //desktop line-height in rem
//   position: relative;
//   overflow: hidden;
// }

.breadcrumbs-hero-internal {
  .breadcrumb-component {
    > .col {
      display: flex;
      justify-content: center;
    }
  }
  .breadcrumb {
    width: min-content;
    overflow: scroll;
    scrollbar-width: none;
  }
}

.fullscreen-modal {
  .modal-container {
    height: calc(100vh - 34px);
    top: 17px;
    width: 394px;
    .modal-footer {
      width: 362px;
    }
  }
}

// .product-wrapper .recommendations {
.product-recommendations {
  h2 {
    font-size: 11px;
    line-height: 17.6px;
    margin-bottom: 3rem;
  }
}

.product-carousel-main {
  // max-width: rem(470);
  // if need fix for 720 height,max width 350px
  margin: 0 auto;
}

.thumbnails {
  .swiper-wrapper {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .swiper-slide {
    margin-bottom: 5px !important;
  }
}

.product-description-controls {
  padding: 0 1rem 0.25rem;
}

// IBT-3745
#product-show {
  .image-carousel-col {
    .product-slide {
      margin-bottom: 80px;
    }
  }

  .product-reassurances-item {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 0;
    &.is-active.is-visible {
      margin: 0.5rem 0;
    }
  }

  .thumbs-carousel-col {
    padding-top: 0;
  }
}