.scrollbar {
  &__thumbPlaceholder{
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    contain: strict;
    transition: opacity 275ms ease;

    &--vertical {
      top:3px;
      right:0;
      width:var(--1fb98f78)
    }

    &--horizontal {
      left:3px;
      bottom:0;
      height:var(--1fb98f78)
    }

    &--autoHide {
      opacity: 0;
    }

    &--scrolling{
      opacity: 1;
    }

    &:hover > .scrollbar__thumb{
      opacity: 1;
    }

    &--autoExpand:hover > .scrollbar__thumb--vertical{
      transform: scaleX(1.5);
    }

    &--autoExpand:hover > .scrollbar__thumb--horizontal{
      transform:scaleY(1.5);
    }
  }
}

.scrollbar__thumb {
  background-color: #000;
  opacity: .6;
  transition: opacity 275ms ease,transform .2s ease;
  contain: strict;
  border-radius: 4px;

  &--vertical{
    width: 2px;
    height: 90%;
  }

  &--horizontal{
    height: 2px;
    width: 90%;
  }
}

.scrollbar__shepherd {
  position: absolute;
  contain: strict;

  &--vertical{
    width: 0%;
    height: 1000;
    top: 0;
    right: 0;
  }

  &--horizontal{
    width: 100%;
    height: 0%;
    bottom: 0;
    left: 0;
  }
}

.scrollbar__wrapper{
  position: relative;
  height: fit-content;

  &:hover > .scrollbar__thumbPlaceholder{
    opacity:1
  }
}

.scrollbar__scroller {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: auto !important;

  &::-webkit-scrollbar{
    display: none;
  }
}

.scrollbar__content {
  &--vertical{
    display: block;
    &.scrollbar__content--fixedThumb{
      display: inline-block;
    }
  }
}

.scrollbar__content--horizontal{
  display:inline-flex
}