.pdp-ddd {
  margin-top: 14px;
}

.cart-item-dynamic-delivery-date {
  border-top: 1px solid #00000014;
  margin: 0 -1rem 0 -0.6rem;

  .product-message-eta {
    padding: 14px !important;
    margin: 0 !important;
  }

  .bag-product__shipping-text.shipping-text-def {
    display: flex;
  }
}

.cart-item-dynamic-delivery-date,
.pdp-home-dynamic-delivery-date {
  display: flex;
  flex-direction: row;

  a {
    cursor: pointer;
  }

  .tooltip {
    position: relative;
    margin-right: 16px;
    align-content: center;

    &:before {
      content: attr(data-text);
      position: absolute;

      top: 50%;
      transform: translateY(-50%);

      left: 100%;
      margin-left: 15px;

      width: 200px;
      padding: 10px;
      background: $white;
      color: $black;
      font-size: 10px;
      font-weight: 500;
      line-height: 12.1px;
      text-align: left;

      display: none;
    }

    &:after {
      content: '';
      position: absolute;

      left: 100%;
      margin-left: -5px;

      top: 50%;
      transform: translateY(-50%);

      border: 10px solid $white;
      border-color: transparent $white transparent transparent;

      display: none;
    }

    &:hover:before,
    &:hover:after {
      display: block;
    }
  }

  .ddd-selector-trigger {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }

  .pdp-home-estimated-date {
    font-size: 11px;
    font-weight: 500;
    line-height: 19.8px;
    text-align: left;
    color: #767674;
  }

  // .ddd-selected-zip {
  //   text-decoration: underline;
  //   font-weight: bold;
  //   cursor: pointer;
  // }

  .ddd-selected-city {
    text-transform: uppercase;
  }
}

.pdp-sts-dynamic-delivery-date {
  display: flex;
  margin-top: 16px;

  .icon-container {
    margin-right: 16px;
    align-content: center;
  }
  .store-ddd-information,
  .sts-ddd-information,
  .pickup-ddd-information {
    font-size: 11px;
    font-weight: 500;
    line-height: 19.8px;
    text-align: left;
    color: #767674;
  }

  .store-ddd-information {
    font-weight: 700;
    text-decoration: underline;
  }

  .sts-ddd-information,
  .pickup-ddd-information {
    display: flex;
    align-items: center;
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      background-color: #fe9d22;
      display: block;
      border-radius: 10px;
      margin-right: 8px;
    }
    &.availablenow::before {
      background-color: #41aa75;
    }
  }
}

#ddd-selector-modal {
  .modal-container.js-modal-container {
    .ddd-modal-title {
      font-size: 13px;
      font-weight: 700;
      line-height: 15.73px;
      letter-spacing: 1px;
      text-align: left;
      text-transform: uppercase;

      height: 62px;
      align-content: center;
      padding: 16px;
      margin: 0;
    }

    .ddd-modal-content {
      padding: 16px;

      .mm-form-search-input input {
        background-image: none;
        padding: 11px;

        &:hover {
          background-image: none;
        }
      }

      .mm-form-message,
      .invalid-feedback {
        background-color: none;
        padding: 0;
      }

      p {
        font-size: 13px;
        font-weight: 500;
        line-height: 20.8px;
        letter-spacing: 0.1px;
        text-align: left;
        padding: 16px 0;
      }

      .btn-ddd-trigger {
        width: 100%;
      }
    }
  }
}
