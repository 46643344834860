#newsletter-modal {
  .modal-container {
    overflow: hidden;
  }

  .modal-content {
    height: calc(100% - 4.5rem);
  }

  .modal-footer {
    display: none;
  }

  [type='submit'] {
    position: static;
    width: 100%;
  }

  .modal-newsletter {
    max-width: rem(385);
  }
}
