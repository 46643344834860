$base_margin: 1rem;

.pd-whiteSpaceDesktop-1x {
  height: $base_margin;
}
.pd-whiteSpaceDesktop-2x {
  height: $base_margin * 2;
}
.pd-whiteSpaceDesktop-3x {
  height: $base_margin * 3;
}
.pd-whiteSpaceDesktop-4x {
  height: $base_margin * 4;
}
.pd-whiteSpaceDesktop-5x {
  height: $base_margin * 5;
}
.pd-whiteSpaceDesktop-6x {
  height: $base_margin * 6;
}
.pd-whiteSpaceDesktop-7x {
  height: $base_margin * 7;
}
.pd-whiteSpaceDesktop-8x {
  height: $base_margin * 8;
}
