.mm-menu-logo {
  width: 164px;
  height: 100%;
  overflow: hidden;
}
.mm-menu-logo__item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(0);
  transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}
.mm-menu-logo__item .mm-logo {
  width: auto;
  height: 26px;
}
.mm-menu-logo__item .mm-stitches {
  width: auto;
  height: 28px;
}
.mm-menu-logo.-flip .mm-menu-logo__item {
  transform: translateY(-100%);
}
