.findinstore-modal {
  .modal-container {
    overflow: hidden;
    width: 25rem;
  }

  .modal-content {
    font-size: 14px;
  }
}

.list-products {
  .title-list {
    & + .unstyled-list {
      margin-top: 3rem;
    }
  }
}

.findinstore-modal {
  .modal-container {
    height: fit-content;
  }
}

#findinstore-modal {
  .search-form-group {
    [type='text'] {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

#bopis-alert {
  .modal-container {
    min-height: auto;
  }

  .modal-header {
    font-size: 12px;
    padding: 2rem 1.5rem;
  }

  .modal-content {
    padding-top: 3rem;

    .mm-button {
      height: 3.25rem;
    }
  }
}

#confirmation-bopis-modal {
  .modal-confirmation-wrap {
    gap: 1.75rem;
  }
}

.checkoutpage.review .checkout-review {
  h3 {
    font-size: 14px;
    line-height: 17px;
  }
}

.bag-review-title {
  font-size: 15px !important;
  line-height: 18px !important;
}

.bag-review-store {
  font-size: 14px;
  line-height: 17px;
}
