.teaser-sidetoside-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .image-video-component {
    border-radius: 0;
  }

  .teaser-sidetoside--item {
    + .teaser-sidetoside--item {
      margin-top: 2rem;
    }
    overflow: hidden;
    &.media-position-right {
      border-top-left-radius: 1.5rem;
      border-top-right-radius: 1.5rem;

      .two-columns-wrapper {
        border-bottom-left-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
        &.-white-background {
          border-top-left-radius: 1.5rem;
          border-top-right-radius: 1.5rem;
          .two-columns__media-region {
            border-radius: 0;
            overflow: hidden;
          }
        }
        .two-columns__media-region {
          border-radius: 1.5rem;
          overflow: hidden;
        }
      }
    }
    &.media-position-left {
      border-bottom-left-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;
      .two-columns-wrapper {
        border-top-left-radius: 1.5rem;
        border-top-right-radius: 1.5rem;
        &.-white-background {
          border-bottom-left-radius: 1.5rem;
          border-bottom-right-radius: 1.5rem;
          .two-columns__media-region {
            border-radius: 0;
            overflow: hidden;
          }
        }
        .two-columns__media-region {
          border-radius: 1.5rem;
          overflow: hidden;
        }
      }
    }
  }

  .two-columns-wrapper {
    flex-direction: column;
    border-radius: 0;
    &.-white-background {
      background: get-color('white');
    }
  }

  .media-position-right {
    .two-columns-wrapper {
      flex-direction: column-reverse;
    }
  }

  .two-columns__text-region,
  .two-columns__media-region {
    width: 100%;
  }

  .two-columns__text-region {
    margin: 2rem 0;
  }
}
