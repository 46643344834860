#newsletter-modal {
  .modal-container {
    overflow: hidden;
  }

  .modal-content {
    height: calc(100% - 9.5rem);
  }

  .modal-footer {
    height: 5rem;
  }

  [type='submit'] {
    position: absolute;
    bottom: 1rem;
    width: calc(100% - 2rem);
  }
}
