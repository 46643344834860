.tooltip-global-container {
  align-items: center;
  display: flex;
  left: 8px;
  position: relative;
  width: min-content;

  .tooltip-toggler {
    height: 1rem;
    width: 1rem;
    position: relative;
    z-index: 1;

    .mm-icon {
      position: absolute;
      top: 0;
    }
  }

  .tooltip-box {
    bottom: 27px;
    display: none;
    left: -16px;
    position: absolute;
    z-index: 1;

    &.visible {
      display: block;
    }
    .tooltip {
      background-color: $ecru-05;
      border: 1px solid $ecru-20;
      border-radius: 4px;
      box-shadow: 0 0 6px -3px $ecru-95;
      height: auto;
      width: 200px;

      .tooltip-content {
        @include type('global-small-2');
        color: get-color('ecru-95');
        padding: 16px;
      }

      .tooltip-tail {
        border: 10px solid;
        border-color: transparent $ecru-05 transparent transparent;
        height: 0;
        left: 12px;
        position: absolute;
        top: calc(100% - 5px);
        transform: rotate(270deg);
        width: 0;
      }
    }
  }
}

.tooltip-container {
  color: get-color('ecru-95');
  > .tooltip-box {
    &:not(.inner-tooltip-box) {
      display: none;
      &.visible {
        display: block;
      }
    }
  }
  .tooltip-trigger {
    position: relative;
    cursor: pointer;
    &:hover {
      .tooltip-box {
        display: block;
      }
    }
    .tooltip-box {
      background: $ecru-05;
      border: 1px solid get-color('ecru-95');
      border-radius: 4px;
      bottom: 24px;
      display: none;
      left: unset;
      right: unset;
      padding: 8px;
      position: absolute;
      width: 250px;
      z-index: 1;
      &.visible {
        display: block;
      }

      &.center-positioned {
        right: unset;
        left: unset;
      }
      &.left-positioned {
        right: unset;
        left: 0;
      }

      &.right-positioned {
        right: 0;
        left: unset;
      }

      .tooltip-tail {
        border: 10px solid;
        border-color: transparent $ecru-20 transparent transparent;
        height: 0;
        left: unset;
        right: 16px;
        position: absolute;
        top: calc(100% - 1px);
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
        width: 0;
      }
      .tooltip-tail-2 {
        border: 10px solid;
        border-color: transparent $ecru-20 transparent transparent;
        height: 0;
        left: unset;
        right: 16px;
        position: absolute;
        top: 100%;
        transform: rotate(270deg);
        width: 0;
      }

      &.tail-centered {
        .tooltip-tail {
          left: calc(50% - 5px);
          right: unset;
        }
        .tooltip-tail-2 {
          left: calc(50% - 5px);
          right: unset;
        }
      }
      &.tail-left {
        .tooltip-tail {
          left: 12px;
          right: unset;
        }
        .tooltip-tail-2 {
          left: 12px;
          right: unset;
        }
      }
      &.tail-right {
        .tooltip-tail {
          left: unset;
          right: 12px;
        }
        .tooltip-tail-2 {
          left: unset;
          right: 12px;
        }
      }
    }
  }
  .link-underline {
    display: inline-flex;
  }
}
