.image-video-cta-wrapper {
  position: relative;
  .experience-assets-MMheadingGroup,
  .experience-assets-MMheadingGroup2CTA {
    position: absolute;
    bottom: 2rem;
    color: $ecru-05;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    width: 100%;
    .mm-button.-outline {
      border-color: $ecru-05;
      color: $ecru-05;
      &:hover {
        @include media-hover {
          color: $black;
          background-color: $ecru-30;
          border-color: $ecru-30;
        }
      }
    }
    .double-cta {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  &.absolute-center {
    .experience-assets-MMheadingGroup,
    .experience-assets-MMheadingGroup2CTA {
      bottom: 0;
      top: 50%;
      transform: translate(-50%, -25%);
    }
  }
  &.absolute-top {
    .experience-assets-MMheadingGroup {
      top: 2rem;
    }
  }
}

#video-overlay {
  .modal-container {
    max-height: none;
    border-radius: 0;
    .modal-header {
      position: absolute;
      top: 0;
      left: 0;
      height: auto;
      width: 100%;
      color: $white;
      z-index: 4;
      svg {
        color: $white;
      }
      .fullscreenvideo-caption {
        position: absolute;
        top: 1rem;
        left: 1rem;
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 20px;
        i {
          margin-right: 0.75rem;
        }
      }
      .modal-close {
        width: rem(40px);
        height: rem(40px);
        padding: 0;
      }
      .modal-close,
      .fullscreenvideo-caption {
        backdrop-filter: blur(20px);
        background: $ecru-dark-20;
      }
    }
    .modal-content {
      padding: 0;
    }
  }

  &.shakaplayer-modal {
    .modal-container {
      width: 100%;
      height: 100%;

      .modal-content {
        align-content: center;
      }

      .shaka-video-container {
        width: 100%;
        max-height: 100%;

        .shakamediaplayer-component {
          width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
