.page-country-selector {
  .accordion-group-list-container {
    margin: 0 !important;
    padding: 1rem 0.5rem 2rem !important;
  }

  .js-country-clear {
    top: 0 !important;
  }
}

.section-country-selector {
  padding: 4rem 0.5rem;
  .subtitle {
    @include type(global-callout-1);
    text-align: center;
    margin-bottom: 1rem;
  }
  .title {
    @include type(mm-headline-2);
    text-align: center;
    margin: 0.5rem auto 5rem;
  }
}

#country-selector-modal {
  .subtitle {
    position: absolute;
    top: 26px;
    left: 1rem;
  }

  .modal-container {
    max-height: calc(100vh - 5.75rem);
    overflow: visible;
  }

  .mm-form-search-input .mm-button {
    top: 0;
    background-color: transparent;
  }

  .section-country-selector {
    padding: 0;
    height: calc(100% - 3rem);
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  #country-list-wrapper {
    padding: 0 10px 50px;
    overflow: visible;
  }

  .accordion-group-list-container ul .accordion.is-active {
    background: transparent;
  }

  .accordion-group-list-container ul .accordion-content {
    border-top: 1px solid rgba(17, 17, 17, 0.06);
  }

  .accordion-group-list-container ul .accordion .accordion-icon {
    opacity: 1 !important;
  }

  .accordion-group-list-item {
    border: 1px solid rgba(17, 17, 17, 0.06);
  }

  form {
    padding: 50px 10px 20px;
    input {
      border: 1px solid rgba(17, 17, 17, 0.06);
      background-color: transparent;
    }
  }
}

.continent {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  + .continent {
    padding-top: 3.75rem;
  }
}

.continent-title {
  @include type(global-control-expressive-3);
  padding-left: 0.75rem;
  padding-bottom: 1rem;
}

.country-item {
  .mm-button {
    width: 100%;
    margin: 0.5rem 0;
    @include type(global-control-productive-3);
  }
}
