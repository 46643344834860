#login-modal,
#forgot-password,
#new-password {
  .modal-container {
    border-radius: 1rem;
    height: auto;
  }
}

#forgot-password,
#new-password {
  /*
  .modal-container {
    min-height: 22.25rem;
  }
  */

  .modal-header {
    margin-bottom: 0.75rem;
  }
}

.modal-login {
  .modal-header {
    padding: 0.8rem 1.5rem 0.5rem;
  }

  .login {
    margin-top: 1.75rem;
  }

  .login-subtitle {
    font-size: 14px;
    line-height: 22.4px;
    letter-spacing: 0.1px;
  }

  .remember-me-section {
    font-size: 13px;
    font-weight: 500;
    line-height: 15.75px;
    margin-bottom: 0.75rem;
  }

  .forgot-button {
    font-size: inherit !important;
    height: auto;
  }

  /*
  .mm-button:not(.-icon) {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
  }
  */

  /*
  .description-forgot-password {
    margin-top: 0.75rem;
  }
  */

  .description-forgot-password-confirm {
    margin-bottom: 2rem;
  }

  .description-new-password {
    margin-bottom: 2rem;
  }

  .description-new-password-confirm {
    margin-bottom: 4rem;
  }

  .modal-new-password-confirm-buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .mm-button:not(.-text) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  .journey-start {
    margin-top: 1rem;
  }

  .g-recaptcha {
    margin-bottom: 2rem;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
