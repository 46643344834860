table {
  @include type('global-control-productive-2');

  th,
  td {
    padding: 1rem 0;
    font-weight: 400;
  }
  &.table-striped {
    text-align: center;
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: $ecru-10;
          td {
            &:first-child {
              border-bottom-left-radius: 10px;
              border-top-left-radius: 10px;
            }
            &:last-child {
              border-bottom-right-radius: 10px;
              border-top-right-radius: 10px;
            }
          }
        }
      }
    }
  }
}
.modal-size-guide {
  table {
    overflow: hidden;
    position: relative;
    thead {
      tr.hightlighet-table-element {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          -webkit-position: absolute;
          border: 2px solid $info-50;
          border-radius: rem(8);
          box-shadow: 0 0 4px 3px rgb(31 106 255 / 27%);
          min-height: rem(45);
          height: inherit;
          max-height: 100%;
          left: 0;
          pointer-events: none;
          // top: 0;
          bottom: '';
          width: 100%;
        }
      }
      th.hightlighet-table-element {
        position: relative;
        &:after {
          content: '';
          border: 2px solid rgba(31, 106, 255, 0.27);
          // box-shadow: 0 0 4px rgba(31, 106, 255, 0.27);
          border-top-left-radius: rem(8);
          border-top-right-radius: rem(8);
          border-bottom: 0;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }

    tbody {
      td.hightlighet-table-element {
        position: relative;
        &:after {
          content: '';
          border: 2px solid rgba(31, 106, 255, 0.27);
          // box-shadow: 0 0 4px rgba(31, 106, 255, 0.27);
          border-bottom: 0;
          border-top: 0;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
        &.first-highlighted {
          &:after {
            content: '';
            border-top: 2px solid $info-50;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            top: 0;
          }
        }
        &.last-highlighted {
          &:after {
            content: '';
            border-bottom: 2px solid rgba(31, 106, 255, 0.27);
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
      tr.hightlighet-table-element {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          -webkit-position: absolute;
          border: 2px solid $info-50;
          border-radius: rem(8);
          box-shadow: 0 0 4px 3px rgb(31 106 255 / 27%);
          min-height: rem(45);
          height: inherit;
          max-height: 100%;
          left: 0;
          pointer-events: none;
          // top: 0;
          bottom: '';
          width: 100%;
        }
      }
    }
  }
}
