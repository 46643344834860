.image-video-component {
  overflow: hidden;

  // IBT-3593
  // border-radius: $border-radius-5;
  // border-radius: $border-radius-7;

  // IBT-3593
  border-radius: $border-radius-12;

  transform: translateZ(0); //Needed for border-radius on Safari :\
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  picture {
    display: block;
    object-fit: cover;
    height: inherit;
  }
}
.single-element-content-image {
  position: relative;
}
.video-link-wrapper {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  pointer-events: auto;
  z-index: 1;
}
.mm-videoloop {
  video {
    object-fit: cover;
  }
  .vjs-big-play-button {
    display: none;
  }
  .vjs-control-bar {
    background-color: transparent;
    //top: 50%;
    transform: translateY(-50%);
    .amp-controlbaricons-middle,
    .amp-controlbaricons-right {
      display: none;
    }
  }
  .vjs-button-tooltip {
    display: none;
  }
  .vjs-play-control {
    width: rem(48px);
    height: rem(32px);
    backdrop-filter: blur(200px);
    background: rgba(17, 17, 17, 0.15);
    border-radius: $border-radius-5;
    margin: 8px;
  }
  &.vjs-user-inactive .vjs-playing .vjs-control-bar {
    opacity: 1;
    z-index: 4;
  }

  // &.hide-buttons {
  .vjs-play-control {
    display: none !important;
  }
  // IBT-3678
  // &:focus,
  // &:focus-within,
  // &:hover {
  //   .vjs-play-control {
  //     display: block !important;
  //     margin: 0 auto;
  //   }
  // }
  // }
}
.wishlistItemCards,
.grid-product,
.experience-layouts-MMCarousel,
.experience-layouts-MMCardsCarousel {
  .mm-videoloop.uninitialized {
    //uninitialized, works as loader
    visibility: hidden;
  }
}

.image-container .mouseover-container .mm-videoloop.uninitialized {
  visibility: visible;

  .vjs-poster img {
    min-width: 100%;
    min-height: 100%;
  }
}

// IBT-3742
.image-video-component {
  .amp-default-skin {
    .vjs-seeking {
      .vjs-loading-spinner {
        display: none;
      }
    }
  }
}

// refactor for Creative reasons IBT-3678
.mm-videoloop {
  // &.hide-buttons {
  .vjs-play-control {
    display: none !important;
  }
  // }
}
// IBT-3678
[lang$='ca'],
[lang$='us'] {
  .mm-videoloop {
    // &.hide-buttons {
    .vjs-play-control {
      display: none !important;
    }
    &:focus,
    &:focus-within,
    &:hover {
      .vjs-play-control {
        display: block !important;
        margin: 0 auto;
      }
    }
    // }
  }
}
//

.experience-component.experience-assets-image_video {
  .image-video-component {
    &.ratio-feature {
      &.ratio-desktop-1x1 {
        @include ratio();
        padding-top: map-get($aspect-ratios, '1x1');

        video {
          aspect-ratio: 1;
        }
      }
      &.ratio-desktop-3x2 {
        @include ratio();
        padding-top: map-get($aspect-ratios, '3x2');

        video {
          aspect-ratio: 3/2;
        }
      }
      &.ratio-desktop-2x3 {
        @include ratio();
        padding-top: map-get($aspect-ratios, '2x3');

        video {
          aspect-ratio: 2/3;
        }
      }
      &.ratio-desktop-4x3 {
        @include ratio();
        padding-top: map-get($aspect-ratios, '4x3');

        video {
          aspect-ratio: 4/3;
        }
      }
      &.ratio-desktop-3x4 {
        @include ratio();
        padding-top: map-get($aspect-ratios, '3x4');

        video {
          aspect-ratio: 3/4;
        }
      }
      &.ratio-desktop-9x16 {
        @include ratio();
        padding-top: map-get($aspect-ratios, '9x16');

        video {
          aspect-ratio: 9/16;
        }
      }
      &.ratio-desktop-16x9 {
        @include ratio();
        padding-top: map-get($aspect-ratios, '16x9');

        video {
          aspect-ratio: 16/9;
        }
      }
    }
  }
}
