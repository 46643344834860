$form-select-chevron-grey: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMTQgNS4zMzMzMUw4IDExLjMzMzNMMiA1LjMzMzMxIiBzdHJva2U9IiNjMWMxYmQiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+');

.return-content {
  .upper-title-row,
  .total-steps,
  .status-description-next-step,
  .o-progress-circle__fill {
    display: none;
  }

  .order-step {
    margin: 8px 0;
    padding-bottom: 24px;

    .summary-return-section {
      margin-bottom: 2rem;
    }

    .horizontal-row {
      width: calc(100% + 37px);
      border-top: 1px solid $ecru-10;
      margin: 16px 0;
      margin-left: -18px;
      &.product-inner-separator {
        width: calc(100% + 1rem);
        margin: 1rem 0 0;
        margin-left: -0.5rem;
      }
      &.upper-return-produtc-row {
        border-color: transparent;
      }
    }

    .return-type-label {
      margin-left: 0.5rem;
      .option-title {
        @include type('global-control-productive-3');
        margin: 1.5rem 0;
      }
    }

    .product-line-item-details {
      @include type('global-control-productive-3');
      .item-attributes {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: -webkit-fill-available;
        margin: 0.5rem 0;

        .attribute-wrapper {
          display: flex;
          flex-direction: column;
          .line-item-attributes {
            display: flex;
            &-label {
              display: flex;
              margin-right: 0.25rem;
            }
            &.color {
              margin: 0.5rem 0;
              order: 1;
            }
            &.quantity-price {
              justify-content: space-between;
              order: 2;
              .item-quantity-value {
                display: flex;
              }
            }
            &.size {
              order: 0;
            }
          }
        }
      }
      .item-image {
        a {
          height: 100%;
        }
      }
    }

    .return-quantity {
      display: none;
    }

    .return-products-wrapper,
    .summary-return-section .product-line-item {
      background-color: $white;
      border-radius: 0.75rem;
      padding: 0.75rem 0.5rem 0.5rem;
    }

    .return-reason-option,
    .return-quantity {
      &.show {
        display: block;
      }
    }

    .color-change-option {
      &.show {
        display: block;
      }
      .size-label-section {
        display: flex;
        justify-content: space-between;
        .size-label {
          text-transform: uppercase;
        }
      }
    }

    .step-subtitle {
      display: none;
    }

    .current-step-title {
      @include type('global-control-expressive-3');
      margin-bottom: 1.75rem;
      text-transform: uppercase;
    }

    .return-quantity-desktop {
      display: none;
    }

    .variation-attributes {
      &.return-flow {
        ul {
          display: flex;
          flex-direction: column;
          list-style-type: none;
          padding-left: 0;
          li {
            .unic-element {
              display: none;
            }
          }
          .color {
            margin-top: 0.625rem;
            order: 1;
          }
        }
      }
    }
  }
  .next-step-button {
    margin-top: 1.5rem;
    width: 100%;
  }
  .card-body {
    display: none;
  }
  .mm-return-toggle-bar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem 0 0.75rem;
    .mm-return-toggle-title {
      @include type('global-control-productive-2');
      margin-bottom: 0;
    }
  }
  .product-badge-wrapper {
    align-items: end;
    display: flex;
    margin: 0.5rem 0.5rem 0 0;
  }
  .product-image {
    border-radius: 0.5rem;
    height: 9.625rem;
    margin-right: 0.75rem;
    width: 6.438rem;
  }
  .return-options-box {
    &.visible {
      display: block;
    }
    .mm-form-select {
      select {
        line-height: unset;
      }
    }
  }
}

.js_order-step2 {
  display: none;
}
.js_order-step3 {
  display: none;
  .default-steps-container {
    .status-description-title {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .section-title {
    @include type('global-control-expressive-3');
    margin-bottom: 2.5rem;
    margin-top: 3.5rem;
  }

  .return-confirmation-product-bottom {
    display: none;
  }
}

.confirm-return-note {
  @include type('global-small-2');
  margin-top: 2rem;
}

.return-confirmation-content {
  .return-confirmation-title {
    margin: 16px 0;
    padding: 0 16px;
    text-transform: uppercase;
  }
  .return-confirmation-img {
    img {
      height: 100px;
    }
  }
}

.page-account-content {
  &.not-logged {
    justify-content: center;
    .return-content {
      .order-step {
        .desktop-steps-container {
          .col-4 {
            .col-3 {
              margin-right: 8px;
              max-width: 48px;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.default-steps-container {
  display: none;
}

.description-box {
  display: none;
}

.steps-flex-box {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 4rem;
  position: relative;
  &:before {
    background: $ecru-95-20;
    content: '';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
  }
  .col-4 {
    background-color: var(--mm-body-bg);
    padding: 0 1rem;
    width: auto;
    z-index: 1;
    .status-circle {
      p {
        @include type('global-control-productive-3');
        color: $ecru-50;
        margin-bottom: 0;
      }
      &.active {
        p {
          color: $ecru-95;
        }
      }
    }
  }

  &.return-step-2:before {
    background: linear-gradient(to left, $ecru-95-20 50%, $ecru-95-40 30%);
  }
  &.return-step-3:before {
    background: $ecru-40;
  }
}

.order-step-content {
  .question-note {
    display: none;
  }
  .option-desc {
    display: none;
  }
}

.select-where-to-return {
  .form-group {
    display: flex;
    flex-direction: column;
  }
}

.quantity-semicolon {
  display: block;
}

.mm-hidden-el {
  display: none !important;
}

.product-line-item {
  p {
    margin-bottom: 0;
  }
}

.product-quantity-price-box {
  align-items: center;
  display: flex;
}

.summary-return-section .product-line-item {
  margin-bottom: 2rem;
}

.order-download {
  a {
    margin-top: 2rem;
    width: 100%;
  }
}

.header-title-box-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 4.75rem;
  margin-top: 2.5rem;
  .header-additional-text {
    @include type('global-body-1');
    max-width: 17.625rem;
    margin: 0 auto;
    margin-top: 1rem;
    text-align: center;
  }
}
.header-title-box {
  display: flex;
  flex-direction: column;
  max-width: 15.625rem;
  margin: 0 auto;
  text-align: center;
  .header-subtitle {
    @include type('global-callout-1');
    margin-bottom: 0.5rem;
  }
  .header-title {
    @include type('mm-headline-1');
  }
}

.return-option-quantity {
  display: none;
}

.return-confirmation-page {
  .action-buttons-wrapper {
    margin-top: 3rem;
    text-align: center;
    .return-confirmation-button {
      padding: 0;
      padding-top: 1rem;
    }
  }
}

.return-confirmation-steps {
  @include type('global-small-2');
  margin: 0 auto;
  max-width: 17.625rem;
  ol {
    padding-left: 1rem;
  }
}

.return-to-home {
  @include type('global-control-productive-3');
}

.return-products-wrapper {
  .mm-form-select {
    select {
      background-color: $ecru-95-10;
      border: none;
    }
  }
}

.return-error-visible {
  &.invalid-feedback {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
  }
}

.order-detail-data-container {
  .line-totals-item.cart-duties-tax {
    .duties-tax-label,
    .tax-total {
      align-items: center;
      display: flex;
    }
  }
}

.select-return-change-type {
  .return-option {
    .radio {
      margin-left: 1rem;
    }
  }
  .mm-form-select {
    .invalid-select {
      color: $ecru-30;
      background-image: $form-select-chevron-grey;
    }
  }
}
