// IBT-3459
// .modal-geoip {
//   .mm-button {
//     text-transform: uppercase;
//   }
// }

#geoip-modal {
  .modal-container {
    height: auto;
  }
}
