.main-footer {
  padding-top: 40px;
  padding-right: 28px;
  padding-left: 28px;

  .container-list-footer {
    margin-top: 32px;
    ul {
      li {
        margin-bottom: 16px;
      }
    }
  }
  .section-footer-box {
    border-bottom: 0;
  }
  .second-section-footer {
    margin-top: 60px;
  }

  .editorial-footer {
    display: flex;
    flex-direction: row;
    > .section-footer-box {
      width: 50%;
      display: block;
    }
  }

  .footer-site-selector {
    padding: 67px 0 33px 0;
  }

  .footer-middle-section {
    margin-left: 5rem;
    text-align: left;
  }
}
