:root {
  --header-height: 116px; //will be overritten in utlis.js
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
}
.main--layout-page {
  padding-top: calc(var(--header-height) + 1rem);

  &.layout-no-padding-top-true {
    padding-top: 0;
  }
}

.notfound-container {
  position: relative;
  text-align: center;
  padding-top: 85vh;

  .text-content {
    width: 300px;
    margin: auto;
  }

  .info-title {
    font-family: 'Margiela Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .info-text {
    font-family: 'Margiela Serif';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
  }

  .image-banner {
    .not-foundbackground {
      width: 350px;
      margin-bottom: 56px;
    }
  }

  .notfound-search-button {
    margin: 60px 0;
    color: #e3e3df;
    width: 100%;
    text-align: left;
    justify-content: left;
    background: transparent;
    border: #e3e3df 2px solid;
  }

  .action-buttons {
    a {
      width: fit-content;
      margin: auto;
      margin-bottom: 10px;
    }

    .blured-button {
      background: rgba(0, 0, 0, 0.08);
      backdrop-filter: blur(32px);
    }
  }
}

.notfound-Immersive {
  color: white;
}

.notfound-Immersive,
.notfound-Image,
.notfound-Text {
  .action-buttons {
    margin-top: 50px;
  }
}

.notfound-container {
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.immersive-banner {
  .not-foundbackground {
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    height: 100vh;
    width: calc(100% - 16px);
    object-fit: cover;
    border-radius: 24px;
  }
}

// IBT-3791
.nofluid {
  margin: 0 auto;
}

.balloon {
  position: fixed;
  bottom: 144px;
  right: 20px;
  z-index: 999;
  width: 100%;
  max-width: 315px;
  background-color: var(--mm-ecru-05);
  border-radius: 13px;
  padding: 12px;
  display: flex;
  opacity: 0;
  box-shadow: 0 1px 5.4px 0 rgba(0, 0, 0, 0.25);
  transition: opacity 0.5s ease-in;

  &.active {
    opacity: 1;
  }

  .balloon-text {
    font-size: 14px;
    margin: 0;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -18px;
    right: 15px;
    z-index: 999;
    border-width: 10px;
    border-style: solid;
    border-color: var(--mm-ecru-05) transparent transparent transparent;
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
  }

  .close-balloon {
    border: none;
    background: none;
    height: 44px;
    width: 44px;
    display: flex;
    padding: 0;
  }
}

.chatbot-entry-point {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 999;
  background: #1a1a1a;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-content: space-around;
  flex-wrap: wrap;
  font-size: 8px;
  color: #fff;
  text-align: center;
  flex-direction: row;
  align-items: center;

  &:hover {
    color: #fff;
  }
  .text {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: -28px;
  }

  .dots {
    font-size: 20px;
    margin-right: -2px;
    letter-spacing: 2px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
  }
}
