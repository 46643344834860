.mm-menu-search .mm-form-search-input {
  + .mm-button {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    z-index: 1;
  }

  .mm-btn-accessibility-search {
    right: 2.5rem;
  }
}

.accessibility-search-label,
.mm-btn-accessibility-search {
  display: none;
}

.mm-form-search-input.mm-form-input:not(.-invalid) input.accessibility-search-input:not(:placeholder-shown):valid {
  background-image: $form-search-input-icon-desktop;
 }

// [aria-controls='search-bar'] {
//   position: absolute;
//   top: 4rem;
// }
// //
