.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55vh;
  position: relative;
}

.mm-spinner {
  --size: 2rem;
  --border-width: 3px;
  position: absolute;
  box-sizing: border-box;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  content: "";
  border: var(--border-width) solid #f7f7f2;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: rotation 1s linear infinite;

  &.-lg {
    --size: 3.5rem;
    --border-width: 4px;
  }

  &.-sm {
    --size: 1rem;
    --border-width: 2px;
  }

  &.-ecru-95 {
    border-color: #1a1a1a #1a1a1a transparent;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(1turn);
  }
}