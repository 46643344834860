.form-chatbox-input {
  .mm-form-fieldset {
    margin-top: 20px;
  }

  .disclaimer {
    font-size: 12px;
  }
}

.start-again-container {
  margin-top: 20px;

  .mm-button {
    &.expired-restart {
      max-width: 300px;
      margin: 0 auto;
    }
  }
}