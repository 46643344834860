.message-notification {
  display: none;
  color: get-color(ecru-05);
  background-color: get-color('ecru-60');
  border-radius: $border-radius-full;
  @include type(global-control-productive-3);
  font-size: 0.875rem;
  max-width: rem(250);
  width: 100%;
  min-height: 3rem;
  padding: 0.25rem 1.5rem;

  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 2;

  &.-show {
    display: flex;
    align-items: center;
    text-align: center;
  }

  &.add-to-cart {
    background-color: get-color('ecru-10');
    text-align: inherit;
    border-radius: $border-radius-5;
    max-width: inherit;
    height: auto;
    color: black;
    padding: 0;

    .notification-close {
      position: absolute;
      right: 1rem;
      align-self: baseline;
      background-color: get-color('ecru-20');
      height: 2rem;
      width: 2rem;
      border-radius: 0.5rem;
    }

    .cart-body-section .product-wrapper .product-info-details {
      .product-name-badge > a {
        padding: 0;
      }

      .product-title {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 200px;
      }

      .bag-product__details-value {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 150px;
        display: block;
      }
    }

    .detail-attribute {
      flex: 1;
    }
  }

  .notification-close {
    // top: 50%;
    margin-right: -0.5rem;
    // position: absolute;
    // transform: translateY(-50%);
    background-color: get-color('ecru-60');
    color: get-color('ecru-05');
    //
  }

  .notification-box-text-icon {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .cart-body-section .product-wrapper .cart-item-grid {
    grid-template-columns: rem(103) rem(75) 1fr;
  }

  .cart-body-section .product-wrapper .product-quantity-wrapper {
    margin-bottom: 2.5rem;
  }

  .cart-body-section .product-wrapper .product-prices-wrapper {
    margin-bottom: 2.5rem;
  }
}
