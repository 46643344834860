.pd-page__content > .experience-block,
.experience-main > .experience-block,
.main-footer,
.experience-component {
  position: relative;
  z-index: 1;
  background-color: var(--mm-body-bg);
  overflow: hidden;
}

.experience-block,
.experience-component {
  &.with-padding-top {
    padding-top: calc(var(--header-height) + 1rem);
    background-color: var(--mm-body-bg);
    margin-top: 1px;
  }
  &.centered-fullscreen {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
  &[data-animation='reveal'] {
    z-index: -1;
    height: 150vh;
    overflow: hidden;
    background-color: black;
    > div {
      z-index: 0;
      opacity: 0;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 100vh;
      width: 100%;
    }
    .experience-assets-image_video .image-video-component {
      border-radius: 0;
      img {
        transform: scale(1.2, 1.2);
      }

      video {
        transform: scale(1.2, 1.2) translateX(0%);
      }
      &:not(.ratio) > a > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      a.resize {
        position: unset;
      }
    }
    &.fixed {
      height: 150vh;
      //Prevents overlapping pinned elements
      // clip: rect(auto, auto, auto, auto);
      // position: static;
      > div {
        position: fixed;
      }
      .image-video-cta-wrapper .experience-assets-MMheadingGroup,
      .image-video-cta-wrapper .experience-assets-MMheadingGroup2CTA {
        position: fixed;
      }
    }
    .experience-assets-image_video {
      .image-video-component:not(.ratio) {
        @include ratio();
        &:before {
          padding-top: 100vh;
        }
      }
    }
    // .experience-assets-MMheadingGroup {
    //   .MMheadingGroup-inner {
    //     height: 100vh;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //   }
    // }
    [data-component='MMCarouselComponent'] {
      height: 100vh;
    }
  }
}

//Fixes
.image-video-cta-wrapper,
.two-columns-wrapper {
  .experience-component {
    background-color: transparent;
  }
}
