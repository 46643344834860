.padding-small {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}
.padding-medium {
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;
}
.padding-large {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
// .margin-extra-large {
//   margin-top: 12rem;
//   margin-bottom: 12rem;
// }
